import React, { useState } from "react";

function Quiz() {
  const [answers, setAnswers] = useState({
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
    answer6: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnswers({ ...answers, [name]: value });
  };

  return (
    <form className="Quiz" onSubmit={handleSubmit}>
        <h1>Evaluacion posterior a la accion</h1>
      <label htmlFor="answer1">Pregunta 1:</label>
      <input
        type="text"
        id="answer1"
        name="answer1"
        value={answers.answer1}
        onChange={handleChange}
      />

      <label htmlFor="answer2">Pregunta 2:</label>
      <input
        type="text"
        id="answer2"
        name="answer2"
        value={answers.answer2}
        onChange={handleChange}
      />

      <label htmlFor="answer3">Pregunta 3:</label>
      <input
        type="text"
        id="answer3"
        name="answer3"
        value={answers.answer3}
        onChange={handleChange}
      />

      <label htmlFor="answer4">Pregunta 4:</label>
      <input
        type="text"
        id="answer4"
        name="answer4"
        value={answers.answer4}
        onChange={handleChange}
      />

      <label htmlFor="answer5">Pregunta 5:</label>
      <input
        type="text"
        id="answer5"
        name="answer5"
        value={answers.answer5}
        onChange={handleChange}
      />

      <label htmlFor="answer6">Pregunta 6:</label>
      <input
        type="text"
        id="answer6"
        name="answer6"
        value={answers.answer6}
        onChange={handleChange}
      />

      <button type="submit">Enviar</button>
    </form>
  );
}

export default Quiz;
