import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ipGlobal from '../config/global'

export default function Journal(){
    const [answers, setAnswers] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"journal"}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
          setAnswers(data.journal)
        })
        .catch((err) => {
          console.log(err);
          navigate("/login")
        });
    }, [])
    function handleSaveJournal() {
        const journalBody = {
            q1: document.getElementById("q1").value,
            q2: document.getElementById("q2").value,
            q3: document.getElementById("q3").value,
            q4: document.getElementById("q4").value,
            q5: document.getElementById("q5").value,
        };
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"journal"}`, {
          method: 'POST',
          body: JSON.stringify(journalBody), 
          headers:{
              'Content-Type': 'application/json',
              'x-access-token': localStorage.getItem('token')
          }
        }).then((response) => response.json())
        .then((data) => {
        setAnswers(data.journal)
        document.getElementById("q1").value = ''
        document.getElementById("q2").value = ''
        document.getElementById("q3").value = ''
        document.getElementById("q4").value = ''
        document.getElementById("q5").value = ''
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
      }
    let body = null
    if (answers){
        body = answers.map((answers) => <div style={divAnswers}>
            <div style={divDate}>{answers.created_at}</div>
            <div style={divComment}>¿Cómo te sientes?</div>
            <div style={divComment}>{answers.q1}</div>
            <div style={divComment}>¿Por qué te sientes así?</div>
            <div style={divComment}>{answers.q2}</div>
            <div style={divComment}>Físicamente ¿Cómo estás?</div>
            <div style={divComment}>{answers.q3}</div>
            <div style={divComment}>Palabra o frase que representa tu día</div>
            <div style={divComment}>{answers.q4}</div>
            <div style={divComment}>Expláyate, es tu diario, escribe lo que quieras... (sácalo todo, reflexiones, aprendizajes, frustraciones, sueños o metas)</div>
            <div style={divComment}>{answers.q5}</div>
        </div>)
    }
    return (
        <div style={{}}>
          <h2 style={{'padding-left': '15%','padding-right': '15%', 'padding-bottom': '20px'}}>Journaling</h2>
          <div style={{'padding-left': '15%','padding-right': '15%', 'font-size': '12px'}}>Es el acto de escribir todos tus sentimientos, pensamientos y percepciones de forma libre en un diario, bitácora o cuaderno y opera como una guía interna para la vida que te motiva a reflexionar y trabajar en el control de las emociones.
          <br></br>Básicamente, se trata de llevar un diario personal con la libertad de escribir, diseñar o crear en el cuando sea necesario.</div>
          <div style={divQuestions}>
            <div style={divQuestion}>¿Cómo te sientes?</div>
            <div><textarea style={divInput} id="q1"></textarea></div>
            <div style={divQuestion}>¿Por qué te sientes así?</div>
            <div><textarea style={divInput} id="q2"></textarea></div>
            <div style={divQuestion}>Físicamente ¿Cómo estás?</div>
            <div><textarea style={divInput} id="q3"></textarea></div>
            <div style={divQuestion}>Palabra o frase que representa tu día</div>
            <div><textarea style={divInput} id="q4"></textarea></div>
            <div style={divQuestion}>Expláyate, es tu diario, escribe lo que quieras... (sácalo todo, reflexiones, aprendizajes, frustraciones, sueños o metas)</div>
            <div><textarea style={divInput} id="q5"></textarea></div>
            <div style={divButtons}>
                <button style={styleButton} onClick={() => {
                    handleSaveJournal();
                    }}>
                    Guardar
                </button>
            </div>
          </div>
          <div>
          {body}
          </div>
        </div>
    )
}

const divInput = {
    "height": "60px",
    "width": "100%"
}

const divQuestion = {
    "margin-top": "20px",
    "margin-bottom": "10px",
}

const divQuestions  = {
    "margin-top": "10px",
    "padding": "10px",
    "width": "70%",
    "margin-bottom": "50px",
}

const divAnswers  = {
    "border-color": "black",
    "border-top": "solid",
    "margin-top": "10px",
    "padding": "10px",
    "width": "70%",
}

 const divDate = {
    "float": "right",
    "font-style": "italic"
 }

 const divComment = {
    "margin-top": "20px",
 }

const divButtons = {
    "float": "right",
}

const styleButton = {
    "padding": "15px",
    "height": "min(100%, 80px)",
    "border-radius": "10px",
    "font-weight": "bold",
    "text-transform": "uppercase",
    "background-color": "#0c3787",
    "color": "#fff",
    "border": "none"
  }