import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ipGlobal from '../config/global'

export default function Mci(){
    const navigate = useNavigate();
    const [answers, setAnswers] = useState();
    useEffect(() => {
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"mci"}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
          console.log(data)
          setAnswers(data.mci)
        })
        .catch((err) => {
          console.log(err);
          navigate("/login")
        });
    }, [])
    function handleSaveMci() {
        const mciBody = {
          q1: document.getElementById("q1").value,
          q2: document.getElementById("q2").value,
          q3: document.getElementById("q3").value,
        };
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"mci"}`, {
          method: 'POST',
          body: JSON.stringify(mciBody), 
          headers:{
              'Content-Type': 'application/json',
              'x-access-token': localStorage.getItem('token')
          }
        }).then((response) => response.json())
        .then((data) => {
        console.log(data)
        setAnswers(data.mci)
        document.getElementById("q1").value = ''
        document.getElementById("q2").value = ''
        document.getElementById("q3").value = ''
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
      }
    let body = null
    if (answers){
        body = answers.map((answers) => <div style={divAnswers}>
            <div style={divQuestion}>¿Cuál es mi meta crucialmente importante (MCI) en este mes? ¿Qué quiero lograr?</div>
            <div style={divAnswer}>{answers.q1}</div>
            <div style={divQuestion}>¿Qué hábito tengo que implementar en mi vida que me ayudará a cumplir con mi MCI?</div>
            <div style={divAnswer}>{answers.q2}</div>
            <div style={divQuestion}>¿Qué mal hábito tengo que sacar de vida este mes para cumplir mi MCI?</div>
            <div style={divAnswer}>{answers.q3}</div>
        </div>)
    }
    return (
        <div>
          <h2 style={{'padding-left': '5%', 'padding-bottom': '20px'}}>Metas Crucialmente Importantes</h2>
          <div style={divQuestions}>
            <div style={divGridQuestion}>
                <div style={divQuestion}>¿Cuál es mi meta crucialmente importante (MCI) en este mes? ¿Qué quiero lograr?</div>
                <div style={divAnswer}><textarea style={divInput} id="q1"></textarea></div>
            </div>
            <div style={divGridQuestion}>
                <div style={divQuestion}>¿Qué hábito tengo que implementar en mi vida que me ayudará a cumplir con mi MCI?</div>
                <div style={divAnswer}><textarea style={divInput} id="q2"></textarea></div>
            </div>
            <div style={divGridQuestion}>
                <div style={divQuestion}>¿Qué mal hábito tengo que sacar de vida este mes para cumplir mi MCI?</div>
                <div style={divAnswer}><textarea style={divInput} id="q3"></textarea></div>
            </div>
            <div style={divButtons}>
                <button style={styleButton} onClick={() => {
                    handleSaveMci()
                    }}>
                    Guardar
                </button>
            </div>
          </div>
          <div>
          {body}
          </div>
        </div>
    )
}

const divQuestions = {
    "padding": "10px",
    "margin-top": "10px",
    "padding": "10px",
    "width": "90%",
    "margin-bottom": "50px",
}

const divAnswers  = {
    "border-color": "black",
    "border-top": "solid",
    "margin-top": "10px",
    "padding": "10px",
    "width": "90%",
}

const divInput = {
    "width": "90%",
    "height": "50px",
}
const divQuestion = {
    "margin-bottom": "10px",
    "font-weight": "bold"
}
const divAnswer = {
    "margin-bottom": "15px"
}

const divGridQuestion = {
}

const divButtons = {
    "float": "right",
    "padding-right": "10%"
}

const styleButton = {
    "padding": "15px",
    "height": "min(100%, 80px)",
    "border-radius": "10px",
    "font-weight": "bold",
    "text-transform": "uppercase",
    "background-color": "#0c3787",
    "color": "#fff",
    "border": "none"
  }