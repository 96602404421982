import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Resizing from './resizing'


export default function NavBar(){

   
    const [page, setPage] = useState("")

    const navigate = useNavigate();

    function handleClick(route) {
        setPage(route)
        navigate(route);
    }

    const Principal = [
        {"name": "Inicio", "url":""},
        {"name": "Login", "url":"login"},
        {"name": "Registro", "url":"registro"},
    ]

    const User = [
        {"name": "Cursos", "url":"courses"},
        {"name": "Ruta de aprendizaje", "url":"courses_basic"},
        {"name": "Recursos", "url":"Recursos"},
        {"name": "Meditaciones", "url":"Meditaciones"},
        {"name": "Balloons", "url":"Balloons"},
        {"name": "Blockers", "url":"blockers"},
        {"name": "Life ScoreCard", "url":"Score-Card"},
        {"name": "Muro De Los Héroes", "url":"DashBoard"},
        {"name": "Mi Porcentaje De Bateo", "url":"MyScore"},
        {"name": "Metas Crucialemnte Importantes", "url":"mci"},
        {"name": "Journaling", "url":"journal"}
        
    ]

    const [content, setContent] = useState(User)

    useEffect(()=>{
        console.log(page)
        if (Principal.map((reg)=> reg.url).includes(window.location.href.split("/").slice(-1)[0])){
            setContent(Principal.splice(0, 2))
        } else if (User.map((reg)=> reg.url).includes(window.location.href.split("/").slice(-1)[0])){
            setContent(User)
        } 
        }, [page, window.location.href])

    return(
        <div>
            <div className="div-image-navbar">
                <Resizing/>
            </div>
            
            <div className="nav-bar-top">
                <div className="nav-bar-router">
                    {content.map((reg)=> <div onClick={() => handleClick(`${reg.url}`)} className="nav-bar-element"> {reg.name} </div>)}
                    
                </div>
               
            </div>
            <div className="nav-bar-bottom"></div>
            
        </div>
    )

    
}