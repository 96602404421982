import React, { useState, useEffect } from 'react';
import ipGlobal from '../config/global'
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const [topStudents, setTopStudents] = useState([]);
  const [topStudentsCompany, setTopStudentsCompany] = useState([]);

  useEffect(() => {
    
    fetch(`${ipGlobal}${"score"}`, {
      method: 'GET',
      headers:{
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        try {
          setTopStudents(data.global)
          setTopStudentsCompany(data.company)
        } catch {
          navigate("/login")
        }
        
      })
      .catch(error => {
        console.log(error);
        navigate("/login")
      });
  }, []);

  function renderTopStudents() {
    return (
      <div>
        <h2>Mejores Calificaciones Global</h2>
        <ul>
          {topStudents.map((student, index) => (
            <li key={index}>
              <span>{index+1}.- {student.fullname}</span>
              <span>{student.score / 10}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  function renderTopStudentsCompany() {
    return (
      <div>
        <h2>Mejores Calificaciones De Mi Empresa</h2>
        <ul>
          {topStudentsCompany.map((student, index) => (
            <li key={index}>
              <span>{index+1}.- {student.fullname}</span>
              <span>{student.score / 10}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="column-1">
        {renderTopStudents()}
      </div>
      <div className="column-2">
        {renderTopStudentsCompany()}
      </div>
    </div>
  );
}

export default Dashboard;
