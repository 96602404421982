import React, { useState } from 'react';
import ipGlobal from '../config/global'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';

function Sidebar(props) {
  const [showEvaluation, setShowEvaluation] = useState(false)
  const [quizAnswers, setQuizAnswers] = useState({})
  let body = null;

  const navigate = useNavigate();
  function changeValues(index, value){
    let newState = quizAnswers
    newState[index] = value
    setQuizAnswers(newState)
  }
  function handleModalSave(){
    handleModalClose()
  //   fetch(`${ipGlobal}${"quiz/user"}`, {
  //     method: 'POST',
  //     body: JSON.stringify({"leasson_id":leasson.id }), 
  //     headers:{
  //         'Content-Type': 'application/json',
  //         'x-access-token': localStorage.getItem('token')
  //     }
  //   }).then((response) => response.json())
  //   .then((data) => {
  //     console.log(data)
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     navigate("/login")
  //   });
  }

  function handleModal(){
    setShowEvaluation(true)
  }
  function handleModalClose(){
    setShowEvaluation(false)
  }

  function clickObject(leasson){
    console.log(leasson);
    props.handleVideo(leasson.url);
    props.handleTitle(leasson.name);
    if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
    fetch(`${ipGlobal}${"user-leassons"}`, {
        method: 'POST',
        body: JSON.stringify({"leasson_id":leasson.id }), 
        headers:{
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
    }).then((response) => response.json())
    .then((data) => {
      console.log(data)
    })
    .catch((err) => {
      console.log(err);
      navigate("/login")
    });
  }
  function createModal(quiz){
    let questions = quiz.questions.map((question, index) => 
    <div style = {divQuestion}>
      {console.log(index)}
      {question.question}
      {question.type == "selectable" ? <select>{question  .selections.map((selection) => <option value={selection.value}>{selection.name}</option>)}</select>: <textarea style={divInput}></textarea>}
    </div>)
    return <div style={divQuestions}>
      <h2>{quiz.name}</h2>
      {questions}
    </div>
  }

  let sll = null;
  if (props.leassons){
    if (props.quiz){
      sll = createModal(props.quiz)
    }
    
    body = props.leassons.map((leasson) => 
      <div onClick={()=>clickObject(leasson)}>
        {leasson.name}
      </div>
    )
  }

  return (
    <div className="sidebar">
      <ul>
        <li className="sidebar-item">
          <span>Curso actual:</span>
          {body}
          
          
          {props.quiz ? <span onClick={handleModal}><br/><br/>Evaluación</span>: null}

          <Modal
                  isOpen={showEvaluation}
                  contentLabel="Example Modal"
              >
                {sll}
                  <div style = {divButtons}>
                      <button style={styleButton} onClick={handleModalClose}>Cerrar</button>
                      <button style={styleButton} onClick={handleModalSave}>Guardar</button>
                  </div>
          </Modal>
        </li>
        <hr/>
      </ul>
    </div>
  );
}

export default Sidebar;


const styleButton = {
  "padding": "5px",
  "margin": "5px",
  "height": "min(100%, 60px)",
  "border-radius": "50px",
  "font-weight": "bold",
  "text-transform": "uppercase",
  "background-color": "#0c3787",
  "color": "#fff",
  "border": "none"
}

const divButtons = {
  "display": "grid",
  "grid-template-columns": "repeat(auto-fit, min(100%, 120px))",
  "gap": "10px",
  "overflow-x": "hidden",
  "justify-content": "end",
  "height": "23%",
  "align-items": "center"
}

const divQuestions = {
  "display": "grid",
  "grid-template-columns": "min(100%, 400px))",
  "grid-template-rows": "60px",
  "gap": "10px",
  "overflow-x": "hidden",
  "justify-content": "center",
  "align-items": "center",
  "height": "77%",
  "width": "100%"
}

const divInput = {
  "height": "60px",
  "width": "100%"
}

const divQuestion = {
  "display": "flex",
  "flex-direction": "column",
  "width": "100%"
}