import { useEffect, useState } from "react";
import Modal from 'react-modal';
import balloonImage from '../static/balloon.png'
import ipGlobal from '../config/global'
import { useNavigate } from "react-router-dom";

export default function Balloons(){
    const navigate = useNavigate();
    const [balloons, setBalloons] = useState([])
    const [id, setId] = useState()
    const [name, setName] = useState("")
    const [question1, setQuestion1] = useState("")
    const [question2, setQuestion2] = useState("")
    const [question3, setQuestion3] = useState("")
    const [question4, setQuestion4] = useState("")
    const [question5, setQuestion5] = useState("")
    const [question6, setQuestion6] = useState("")
    const [question7, setQuestion7] = useState("")
    const [question8, setQuestion8] = useState("")
    const [question9, setQuestion9] = useState("")
    const [question10, setQuestion10] = useState("")
    const [open, setOpen] = useState(false)
    
    const handleName = event => {
        setName(event.target.value);
    };
    const handleQuestion1 = event => {
        setQuestion1(event.target.value);
    };
    const handleQuestion2 = event => {
        setQuestion2(event.target.value);
    };
    const handleQuestion3 = event => {
        setQuestion3(event.target.value);
    };
    const handleQuestion4 = event => {
        setQuestion4(event.target.value);
    };
    const handleQuestion5 = event => {
        setQuestion5(event.target.value);
    };
    const handleQuestion6 = event => {
        setQuestion6(event.target.value);
    };
    const handleQuestion7 = event => {
        setQuestion7(event.target.value);
    };
    const handleQuestion8 = event => {
        setQuestion8(event.target.value);
    };
    const handleQuestion9 = event => {
        setQuestion9(event.target.value);
    };
    const handleQuestion10 = event => {
        setQuestion10(event.target.value);
    };
    useEffect(() => {
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"balloons"}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
          console.log(data.balloons)
          setBalloons(data.balloons)
        })
        .catch((err) => {
          console.log(err);
          navigate("/login")
        });
    }, [open, id, name]);

    function handleSave(){
        let form = handleGenerateForm()
        if(id){
            handleModify(form)
        } else {
            handleCreate(form)
        }
        handleClose()
    }

    function handleModify(form){
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"balloon/"}${id}`, {
            method: 'PUT',
            body: JSON.stringify(form), 
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
        console.log(data)
        handleClose()
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
    }
    function handleCreate(form){
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"balloons"}`, {
            method: 'POST',
            body: JSON.stringify(form), 
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
        console.log(data)
        handleClose()
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
    }
    function handleDelete(){
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"balloon/"}${id}`, {
            method: 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
        console.log(data)
        handleClose()
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
    }
    function handleClose(){
        setOpen(false)
        handleCleanQuestions()
    }
    function handleOpen(){
        setOpen(true)
    }
    function handleOpenEdit(balloon){
        console.log(balloon)
        setId(balloon.id)
        setName(balloon.name)
        setQuestion1(balloon.question1)
        setQuestion2(balloon.question2)
        setQuestion3(balloon.question3)
        setQuestion4(balloon.question4)
        setQuestion5(balloon.question5)
        setQuestion6(balloon.question6)
        setQuestion7(balloon.question7)
        setQuestion8(balloon.question8)
        setQuestion9(balloon.question9)
        setQuestion10(balloon.question10)
        handleOpen()
    }
    function handleCleanQuestions(){
        setName("")
        setQuestion1("")
        setQuestion2("")
        setQuestion3("")
        setQuestion4("")
        setQuestion5("")
        setQuestion6("")
        setQuestion7("")
        setQuestion8("")
        setQuestion9("")
        setQuestion10("")
        setId(null)
    }
    function handleGenerateForm(){
        return {
            name: name,
            question1: question1,
            question2: question2,
            question3: question3,
            question4: question4,
            question5: question5,
            question6: question6,
            question7: question7,
            question8: question8,
            question9: question9,
            question10: question10
        }
    }

    return (
        <div>
            <div>
                <button style={styleButton} onClick={handleOpen}>Agregar Balloon</button>
            </div>
            <div style={divBalloons}>
                {balloons.map((balloon)=><div style={divNameBalloon}><img src={balloonImage} height={'200px'} onClick={()=>handleOpenEdit(balloon)}/>{balloon.name}</div>)}
            </div>
            
            <Modal
                isOpen={open}
                contentLabel="Example Modal"
            >
                <div style={divQuestions}>
                    ¿Cúal es el nombre del problema?
                    <textarea style={divInput} value={name} onChange={handleName}></textarea>
                    ¿Qué está pasando por tu mente en este momento?
                    <textarea style={divInput} value={question1} onChange={handleQuestion1}></textarea>
                    En la escala del 1 al 10, ¿Que tanto te está molestando esto? (siendo 1 - No mucho / 10 - Extremadamente)
                    <textarea style={divInput} value={question2} onChange={handleQuestion2}></textarea>
                    Describe como te sientes:
                    <textarea style={divInput} value={question3} onChange={handleQuestion3}></textarea>
                    ¿Por qué piensas que esto te está afectando?
                    <textarea style={divInput} value={question4} onChange={handleQuestion4}></textarea>
                    ¿Qué variables puedes cambiar?
                    <textarea style={divInput} value={question5} onChange={handleQuestion5}></textarea>
                    ¿Qué pensamientos te harían sentir mejor?
                    <textarea style={divInput} value={question6} onChange={handleQuestion6}></textarea>
                    ¿Cuáles son los beneficios de esto que está pasando?
                    <textarea style={divInput} value={question7} onChange={handleQuestion7}></textarea>
                    ¿Qué necesitas hacer ahora?
                    <textarea style={divInput} value={question8} onChange={handleQuestion8}></textarea>
                    Después de responder las preguntas anteriores, ¿te encuentras Sobre La Línea?
                    <textarea style={divInput} value={question9} onChange={handleQuestion9}></textarea>
                    Califica como te sientes en este momento. (Vuelve a rellenar el cuestionario si tu calificación es mayor que 3)
                    <textarea style={divInput} value={question10} onChange={handleQuestion10}></textarea>
                    Si no encontraste la respuesta que esperabas o necesitabas, entonces haz lo siguiente:<br></br><br></br>
                    - Haz una pausa y trata de relajarte.<br></br>
                    - Inhala y exhala 3 veces lentamente.<br></br>
                    - Hazte consciente de tus pensamientos y de tu actitud, evita los señalamientos, las excusas, y las justificaciones. Trata de hacerte responsable, de ser autocrítico, trata de ir sobre la línea.<br></br>
                    - Recuerda que estar <b>SOBRE LA LÍNEA</b> Es buscar soluciones, colaborar y contruir en pro de tu persona, equipo, empresa y relación.<br></br>
                    <b>STOP</b>
                    - Es muy importante en ocasiones como estas hacer una pausa, repetir en nuestra cabeza la palabra STOP y cuidar nuestro diálogo interno para no hacer los problemas más grandes (siéntate y cierra tus ojos).<br></br>
                </div>
                <div style = {divButtons}>
                    <button style={styleButton} onClick={handleClose}>Cerrar</button>
                    <button style={styleButton} onClick={handleSave}>Guardar</button>
                    {id ? <button style={styleButton} onClick={handleDelete}>Reventar balloon</button> : null}
                </div>
            </Modal>
        </div>
    )
}

const styleButton = {
    "padding": "5px",
    "margin": "5px",
    "height": "min(100%, 60px)",
    "border-radius": "50px",
    "font-weight": "bold",
    "text-transform": "uppercase",
    "background-color": "#0c3787",
    "color": "#fff",
    "border": "none"
}

const divButtons = {
    "display": "grid",
    "grid-template-columns": "repeat(auto-fit, min(100%, 120px))",
    "gap": "10px",
    "overflow-x": "hidden",
    "justify-content": "end",
    "height": "23%",
    "align-items": "center"
}

const divBalloons = {
    "display": "grid",
    "grid-template-columns": "repeat(auto-fit, min(100%, 200px))",
    "gap": "10px",
    "overflow-x": "hidden",
    "justify-content": "center",
    "align-items": "center",
}

const divNameBalloon = {
    "display": "grid",
    "grid-template-columns": "200px",
    "grid-template-rows": "200px",
    "gap": "5px",
    "overflow-x": "hidden",
    "justify-content": "center",
    "align-items": "center",
    "font-weight": "bold",
    "text-transform": "uppercase",
}

const divQuestions = {
    "display": "grid",
    "grid-template-columns": "min(100%, 400px))",
    "grid-template-rows": "60px",
    "gap": "10px",
    "overflow-x": "hidden",
    "justify-content": "center",
    "align-items": "center",
    "height": "77%"
}

const divInput = {
    "height": "60px"
}