import React, { useState, useRef } from "react";
import { PolarArea } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
const options= {
  scales: {
    r: {
      min: 0,
      max: 10,
      beginAtZero: true,
    },
  }
}

const Formulario = () => {
  const [pregunta1, setPregunta1] = useState("");
  const [pregunta2, setPregunta2] = useState("");
  const [pregunta3, setPregunta3] = useState("");
  const [pregunta4, setPregunta4] = useState("");
  const [pregunta5, setPregunta5] = useState("");
  const [pregunta6, setPregunta6] = useState("");
  const [pregunta7, setPregunta7] = useState("");
  const [pregunta8, setPregunta8] = useState("");
  const [pregunta9, setPregunta9] = useState("");
  const [pregunta10, setPregunta10] = useState("");

  
  const [data, setData] = useState(null);
  const chartRef = useRef(null);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    destroyChart();

    const opciones = [  pregunta1,  pregunta2,  pregunta3,  pregunta4,  pregunta5,  pregunta6,  pregunta7,  pregunta8,  pregunta9, pregunta10];

    let data_options=[];
    opciones.forEach((opcion) => {
    if (opcion === "opcion1") {
    data_options.push(1);
    } else if (opcion === "opcion2") {
    data_options.push(4);
    } else if (opcion === "opcion3") {
    data_options.push(7);
    } else if (opcion === "opcion4") {
    data_options.push(10);
    }

});

const datos = {
  labels: [
    "Autoestima", "Emocionado", "Consciente", "Patrones de sueño", "Dieta", "Condición física", "Forma de expresarme", "Ahora", "Dejar ir", "Life Balance"
  ],
  backgroundColor:'black',
  datasets: [
    {
      data: data_options,
      backgroundColor: [
        "rgba(255, 0, 0, .5)",
        "rgba(0, 255, 0, .5)",
        "rgba(0, 0, 255, .5)",
        "rgba(255, 255, 0, .5)",
        "rgba(255, 0, 255, .5)",
        "rgba(0, 255, 255, .5)",
        "rgba(255, 165, 0, .5)",
        "rgba(138, 43, 226, .5)",
        "rgba(10, 120, 120, .5)",
        "rgba(255, 105, 180, .5)",
      ],
      borderColor: [
        "rgba(255, 0, 0, 1)",
        "rgba(0, 255, 0, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(255, 255, 0, 1)",
        "rgba(255, 0, 255, 1)",
        "rgba(0, 255, 255, 1)",
        "rgba(255, 165, 0, 1)",
        "rgba(138, 43, 226, 1)",
        "rgba(10, 120, 120, 1)",
        "rgba(255, 105, 180, 1)",
      ],
      borderWidth: 1,
    },
  ],
};
setData(datos);
};
  const destroyChart = () => {
    if (chartRef.current && chartRef.current.chartInstance) {
      const chartInstance = chartRef.current.chartInstance;
      const chartCanvas = chartInstance.canvas;
      const chartId = chartCanvas.id;
      chartInstance.destroy();
      const newCanvas = document.createElement('canvas');
      newCanvas.id = chartId;
      chartCanvas.parentNode.replaceChild(newCanvas, chartCanvas);
      setData(null);
    }
  };

  return (
    <div>
      <div style={polarArea}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Life ScoreCard</h1>
        <p style={{ fontSize: '18px', lineHeight: '1.5', marginBottom: '20px' }}>
        Todos tenemos muchas cosas en nuestras vidas: trabajo, familia, amigos y más, y puede ser difícil saber dónde poner nuestra energía para marcar una diferencia real. Pero sabemos una cosa con certeza: todo lo demás encaja cuando estás feliz, saludable y seguro de ti mismo y de tus relaciones con los demás.
        ¿Crees que es hora de hacer un cambio?<br/>
        Tenemos la herramienta perfecta para ayudarlo a comenzar.<br/><br/>
        Nuestro LIFE SCORECARD es una encuesta simple de 10 preguntas que cubre áreas clave de nuestro bienestar: físico, emocional, social, intelectual y espiritual.<br/>
        Una vez que responda las preguntas y obtenga su puntaje, podrá ver fácilmente dónde es fuerte y dónde necesita un poco de trabajo. Pero adivina qué, ¡eso es solo el comienzo! Puede tomar medidas revisando nuestros consejos y recursos personalizados en función de su puntuación.
        </p>
        <form onSubmit={handleSubmit} >
          
        <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Amo ser yo:</label>
            <br/>
            <select value={pregunta1} onChange={(e) => setPregunta1(e.target.value)}>
              <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label> Estoy emocionado por la vida:</label>
            <br/>
            <select value={pregunta2} onChange={(e) => setPregunta2(e.target.value)}>
              <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Estoy consciente y conectado con mi verdadero potencial:</label>
            <br/>
            <select value={pregunta3} onChange={(e) => setPregunta3(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Tengo sueño de calidad consistentemente:</label>
            <br/>
            <select value={pregunta4} onChange={(e) => setPregunta4(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Ingiero comida saludable:</label>
            <br/>
            <select value={pregunta5} onChange={(e) => setPregunta5(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Cuido mi cuerpo con ejercicio físico:</label>
            <br/>
            <select value={pregunta6} onChange={(e) => setPregunta6(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Mi conversación interna es motivadora y positiva:</label>
            <br/>
            <select value={pregunta7} onChange={(e) => setPregunta7(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Consistentemente vivo el momento y no me preocupo por el pasado y el futuro:</label>
            <br/>
            <select value={pregunta8} onChange={(e) => setPregunta8(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Me perdono a mi y a los demás rápidamente:</label>
            <br/>
            <select value={pregunta9} onChange={(e) => setPregunta9(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }}>
            <label>Consistentemente me doy el tiempo para hacer cosas que me gustan o para tener tiempo de calidad en mis relaciones:</label>
            <br/>
            <select value={pregunta10} onChange={(e) => setPregunta10(e.target.value)}>
            <option value="">Selecciona una opción</option>
              <option value="opcion1">Nunca</option>
              <option value="opcion2">Poco</option>
              <option value="opcion3">Ocasionalmente</option>
              <option value="opcion4">Frecuentemente</option>
            </select>
          </div>
          <br/>
          <button type="submit" className="boton123" >Enviar</button>
        </form>
        <br/>
        <p>Mire las áreas donde tiene espacio para mejorar y tome la decisión de expandir esas áreas en su vida.</p>
      </div>
        {data && (
          <div>
            <br/>
            <div style={{"width": "max(400px, 40%)"}}><PolarArea data={data} options={options} ref={chartRef}/></div>
            <button className="boton123" onClick={()=>{
              const link = document.createElement('a');
              link.download = "chart.jpeg";
              link.href = chartRef.current.toBase64Image('image/jpeg', 1);
              link.click();
            }}> Descargar </button>
            
          </div>
        )}
      
    </div>
    
  );
};

export default Formulario;

const polarArea = {
  "width": "min(100%, 600px)",
}
