
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';






const RegisterPage  = () =>  {
    const [fullName, setfullName] = useState ('');
    const [correo, setCorreo] = useState ('');
    const [contraseña, setContra] = useState ('');
    const [repitContra, setRepit] = useState ('');
    const [phone, setPhone] = useState ('');
    const [empresa, setEmpresa] = useState ('');
    const [puesto, setPuesto] = useState ('');
    const [edad, setEdad] = useState ('');
    const [pais, setPais] = useState ('');
    const [estado, setEstado] = useState ('');
    const [ciudad, setCiudad] = useState ('');
    const [admin, setAdmin] = useState ('');
    const navigate = useNavigate();
    
   


    const enviar = () => {
        if (fullName.target !== undefined && correo.target !== undefined && contraseña.target !== undefined && repitContra.target !== undefined &&
            phone.target !== undefined && empresa.target !== undefined && puesto.target !== undefined && edad.target !== undefined && pais.target !== undefined && estado.target !== undefined && ciudad.target !== undefined){
                let nombreRequest = fullName.target.value;
                let correoRequest = correo.target.value;
                let contraseñaRequest = contraseña.target.value;
                let phoneRequest = phone.target.value;
                let empresaRequest = empresa.target.value;
                let puestoRequest = puesto.target.value;
                let edadRequest = edad.target.value;
                let paisRequest = pais.target.value;
                let estadoRequest = estado.target.value;
                let ciudadRequest = ciudad.target.value;
                let repitContraRequest = repitContra.target.value;
                let adminRequest = admin.target.value;

                
                if (correoRequest.indexOf("@") === -1){
                    alert("Correo electrónico mal formado")
                    return null;
                }
                if (contraseñaRequest !== repitContraRequest){
                    alert("Contraseñas incorrectas")
                    return null;
                }



                const request = {
                    fullname: nombreRequest,
                    email: correoRequest,
                    password: contraseñaRequest,
                    phone: phoneRequest,
                    company: empresaRequest,
                    position: puestoRequest,
                    birthdate: edadRequest,
                    country: paisRequest,
                    state: estadoRequest,
                    city: ciudadRequest,
                    admin: adminRequest

                }
                console.log("click",request);
                if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
                fetch("http://127.0.0.1:5000/api/v1/usersAdmin", {
                method: 'POST',
                body: JSON.stringify(request), 
                headers:{
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token')
                }
            }).then((response) => response.json())
            .then((data) => {
              console.log(data);
              if (data.messages) {
                alert(data.messages);
              } else {
                localStorage.setItem('token', data.token);
                navigate("/reporteUsuarios");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        else {
            alert("Favor de llenar los campos")
        }
         }





    
    return <div className='regist'>

       
        <div className='REGÍSTRATE'>
            <h2  >Registro</h2>
            <div>
                <label>Nombre</label>
                <br/>
                <input className='regist-input' type= "text" name='' onChange={setfullName} required={true}  />
            </div>
            <br/>
            <div>
                <label>Email</label>
                <br/>
                <input className='regist-input' type= "email" name='' onChange={setCorreo}  />
            </div>
            <br/>
            <div>
                <label>Contraseña</label>
                <br/>
                <input className='regist-input' type= "password" name='' onChange={setContra} />
            </div>
            <br/>
            <div>
                <label>Repetir Contraseña</label>
                <br/>
                <input className='regist-input' type= "password" name='' onChange={setRepit} />
            </div>
            <br/>
            <div>
                <label>Telefono</label>
                <br/>
                <input className='regist-input' type= "text" name='' onChange={setPhone} />
            </div>
            <br/>
            <div>
                <label>Empresa</label>
                <br/>
                <input className='regist-input' type= "text" name='' onChange={setEmpresa} />
            </div>
            <br/>
            <div>
                <label>Puesto</label>
                <br/>
                <input className='regist-input' type= "text" name='' onChange={setPuesto} />
            </div>
            <br/>
            <div>
                <label>Fecha de nacimiento</label>
                <br/>
                <input className='regist-input' type= "date" name='' onChange={setEdad} />
            </div>
            <br/>
            <div>
                <label>Pais</label>
                <br/>
                <input className='regist-input' type= "text" name='' onChange={setPais} />
            </div>
            <br/>
            <div>
                <label>Estado</label>
                <br/>
                <input className='regist-input' type= "text" name='' onChange={setEstado} />
            </div>
            <br/>
            <div>
                <label>Ciudad</label>
                <br/>
                <input className='regist-input' type= "text" name='' onChange={setCiudad} />
            </div>
            <br/>
            <div>
                <label>Admin</label>
                <br/>
                <input  className='checkbox' type= "checkbox" name='' onChange={setAdmin} />
            </div>
            <br/>
            <input className='btn' type="submit" value="Registrar" name='Registrar'  onClick={enviar}/>
            
        </div>

       
    </div>

}

export default RegisterPage;