import React,{ useState, useEffect } from "react";
import { PolarArea } from "react-chartjs-2";
import ipGlobal from '../config/global'
import { useNavigate } from "react-router-dom";
import { useData } from './DataContext';

export default function MiScore() {
  const navigate = useNavigate();
  const [codigoLider, setCodigoLider] = useState([]);
  const [pmv, setPmv] = useState(null);
  const [inputValue, setInputValue] = useState();
  useEffect(() => {
    if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
    fetch(`${ipGlobal}${"pmv"}`, {
        method: 'GET',
        headers:{
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
    }).then((response) => response.json())
    .then((data) => {
      setPmv(data.pmv)
    })
    .catch((err) => {
      console.log(err);
      navigate("/login")
    });
}, [])
  const options= {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      r: {
        min: 0,
        max: 10,
        beginAtZero: true,
      },
    }
  }

  let data = {
    labels: [
      "Estar sobre la línea",
      "Aplicar Johnny Bravo",
      "Aplicar Verde y Limpio",
      "Hacer algo bueno por mi familia",
      "Hacer algo para mantenerme en forma",
      "Alimentarme bien",
      "No hacer multitasking",
      "Respetar mi agenda",
    ],
    datasets: [
      {
        label: "Código de Líder",
        data: [
          codigoLider?.q1 || 0,
          codigoLider?.q2 || 0,
          codigoLider?.q3 || 0,
          codigoLider?.q4 || 0,
          codigoLider?.q5 || 0,
          codigoLider?.q6 || 0,
          codigoLider?.q7 || 0,
          codigoLider?.q8 || 0,
        ],
        backgroundColor: [
          "rgba(255, 0, 0, .5)",
          "rgba(0, 255, 0, .5)",
          "rgba(0, 0, 255, .5)",
          "rgba(255, 255, 0, .5)",
          "rgba(255, 0, 255, .5)",
          "rgba(0, 255, 255, .5)",
          "rgba(255, 165, 0, .5)",
          "rgba(138, 43, 226, .5)",
        ],
        borderColor: [
          "rgba(255, 0, 0, 1)",
          "rgba(0, 255, 0, 1)",
          "rgba(0, 0, 255, 1)",
          "rgba(255, 255, 0, 1)",
          "rgba(255, 0, 255, 1)",
          "rgba(0, 255, 255, 1)",
          "rgba(255, 165, 0, 1)",
          "rgba(138, 43, 226, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  

  useEffect(() => {
    
    fetch(`${ipGlobal}${"leader/7"}`, {
      method: 'GET',
      headers:{
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        setCodigoLider(data)
      })
      .catch(error => {
        console.log(error);
        navigate("/login")
      });
  }, []);
  let body = null;
  if (pmv){
    body = (
      <div style={{"padding": '20px'}}>
        <h2>Prioridad Más Valiosa</h2>
        {pmv.map((data)=><div>- {data["pmv"]}</div>)}
      </div>
    )
  }

  return (
    <div>
      {body}
      <h2 style={{"padding": '20px'}}>Mi Porcentaje De Bateo</h2>
      <div>
      <div style={{"width": "max(400px, 40%)"}}><PolarArea data={data} options={options}/></div>
      <br/>
        {/* <p>¿A qué me comprometo para mejorar en mi sección más baja?</p>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button onClick={() => setInputValue('')}>Guardar</button> */}
      </div>
    </div>
  );
  
}
