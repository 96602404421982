import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modalconfig from './modalconfig'
import ipGlobal from '../config/global'
import Square from './square'
import Modal from 'react-modal';
import { useData } from './DataContext'


export default function Courses(){
    const [courses, setCourses] = useState();
    const { setCodigoLider } = useData();

    const navigate = useNavigate();
    
    const [dayMessage, setDayMessage] = useState()
    const [dayMessageAuthor, setDayMessageAuthor] = useState()
    const [dayModalShow, setDayModalShow] = useState(localStorage.getItem('message') == 'true')
    const [dayModalOpen, setDayModalOpen] = useState(localStorage.getItem('leader') == 'true')
    const [dayMonthOpen, setDayMonthOpen] = useState(localStorage.getItem('epa') == 'true')
    const [pmvOpen, setPmvOpen] = useState(localStorage.getItem('pmv') == 'true')
    const [leaderMessage, setLeaderMessage] = useState({})
    function handleLeaderMessage(field, value){
      let presetValues = leaderMessage;
      presetValues[field] = value;
      setLeaderMessage(presetValues)
    }
    function handleCloseModalDaily(){
      setDayModalOpen(false)
    }
    function handleCloseMonthDaily(){
      setDayMonthOpen(false)
    }
    function handleClosePmv(){
      setPmvOpen(false)
    }

    useEffect(() => {
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"courses"}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
          console.log(data.courses)
          setCourses(data.courses)
        })
        .catch((err) => {
          console.log(err);
          navigate("/login")
        });
        if(localStorage.getItem("message") == 'true'){
          localStorage.setItem('message', false);
          if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
          fetch(`${ipGlobal}${"daily-message"}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
          }).then((response) => response.json())
          .then((data) => {
            console.log(data);
            setDayMessage(data.message)
            setDayMessageAuthor(data.author)
            // setDayModalShow(false)
          })
          .catch((err) => {
            console.log(err);
            navigate("/login")
          });
        }
    }, []);
    let body = null;
    if (courses){
        // courses.map((course) => Square(course))
        body = courses.map((course) => <Square course = {course}/>)
    }
    function handleSave() {
      const codigoLiderResponses = {
        q1: parseInt(getElementValue("estar-sobre-la-linea")),
        q2: parseInt(getElementValue("aplicar-johnny-bravo")),
        q3: parseInt(getElementValue("aplicar-verde-y-limpio")),
        q4: parseInt(getElementValue("hacer-algo-bueno-por-mi-familia")),
        q5: parseInt(getElementValue("hacer-algo-para-mantenerme-en-forma")),
        q6: parseInt(getElementValue("alimentarme-bien")),
        q7: parseInt(getElementValue("meditar")),
        q8: parseInt(getElementValue("respetar-mi-agenda")),
      };
      if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
      fetch(`${ipGlobal}${"leader"}`, {
        method: 'POST',
        body: JSON.stringify(codigoLiderResponses), 
        headers:{
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
      }).then((response) => response.json())
      .then((data) => {
      console.log(data)
      localStorage.setItem('leader', false);
      })
      .catch((err) => {
      console.log(err);
      navigate("/login")
      });
    }
    function handleSaveEpa() {
      const epaResponses = {
        q1: getElementValue("q1"),
        q2: getElementValue("q2"),
        q3: getElementValue("q3"),
        q4: getElementValue("q4"),
        q5: getElementValue("q5"),
        q6: getElementValue("q6"),
      };
      if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
      fetch(`${ipGlobal}${"epa"}`, {
        method: 'POST',
        body: JSON.stringify(epaResponses), 
        headers:{
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
      }).then((response) => response.json())
      .then((data) => {
      console.log(data)
      localStorage.setItem('epa', false);
      })
      .catch((err) => {
      console.log(err);
      navigate("/login")
      });
    }
    function handleSavePmv() {
      const epaResponses = {
        pmv: getElementValue("pmv")
      };
      if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
      fetch(`${ipGlobal}${"pmv"}`, {
        method: 'POST',
        body: JSON.stringify(epaResponses), 
        headers:{
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
      }).then((response) => response.json())
      .then((data) => {
      console.log(data)
      localStorage.setItem('pmv', false);
      })
      .catch((err) => {
      console.log(err);
      navigate("/login")
      });
    }


  function getElementValue(id) {
    const element = document.getElementById(id);
    return element ? element.value : "";
  }

  return (
    <div>
      <div>
        <Modalconfig
          title="Mensaje del día"
          message={dayMessage}
          show={dayModalShow}
          author={dayMessageAuthor}
        />
      </div>
      <Modal isOpen={dayModalOpen} contentLabel="Example Modal">
        <div style={divQuestions}>
          <h1>Código de Líder</h1>
          <br />
          <h3>Hice todo lo posible ayer para:</h3>
          <br />
          <br />
          Estar Sobre la Línea:
          <br />
          <select id="estar-sobre-la-linea">
            <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
          <br />
          Aplicar Johnny Bravo:
          <select id="aplicar-johnny-bravo">
          <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
          <br />
          Aplicar Verde y Limpio:
          <select id="aplicar-verde-y-limpio">
          <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
          <br />
          Hacer algo bueno por mi familia:
          <select id="hacer-algo-bueno-por-mi-familia">
          <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
          <br />
          Hacer algo para mantenerme en forma:
          <select id="hacer-algo-para-mantenerme-en-forma">
          <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
          <br />
          Alimentarme bien:
          <select id="alimentarme-bien">
          <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
          <br />
          No hacer multitasking:
          <select id="meditar">
          <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
          <br />
          Respetar mi agenda:
          <select id="respetar-mi-agenda">
          <option value="">Selecciona una opción</option>
            <option value="0">No</option>
            <option value="10">Si</option>
            <option value="5">Regular</option>
          </select>
        </div>
        <div style={divButtons}>
          <button
            style={styleButton}
            onClick={() => {
              handleCloseModalDaily();
              handleSave();
            }}
          >
            Guardar
          </button>
        </div>
      </Modal>
      <Modal isOpen={dayMonthOpen} contentLabel="Example Modal">
        <div style={divQuestions}>
          <h2>Evaluación Posterior a la Acción (EPA)</h2>
          ¿Qué querías lograr este mes?
          <textarea style={divInput} id="q1"></textarea>
          ¿Qué aprendiste este mes?
          <textarea style={divInput} id="q2"></textarea>
          ¿Qué acciones implementaste de acuerdo a lo que aprendiste los últimos 30 días?
          <textarea style={divInput} id="q3"></textarea>
          ¿Cuáles fueron tus resultados?
          <textarea style={divInput} id="q4"></textarea>
          ¿Qué hiciste bien y tienes que seguir haciendo?
          <textarea style={divInput} id="q5"></textarea>
          Escribe de 1 a 3 acciones que implementarás para el siguiente mes en busca de seguir creciendo y mejorando
          <textarea style={divInput} id="q6"></textarea>
        </div>
        <div style={divButtons}>
          <button style={styleButton} onClick={() => {
              handleCloseMonthDaily();
              handleSaveEpa();
            }}>
            Guardar
          </button>
        </div>
      </Modal>
      <Modal isOpen={pmvOpen} contentLabel="Example Modal">
        <div style={divPMV}>
          <br></br>
          <div style={{"font-weight": "bold"}}>¿Cuál es mi MVP para mañana?</div>
          <div>Prioridad Más Valiosa que si ejecuto tendrá un impacto en mi productividad</div>
          <textarea style={divInput} id="pmv"></textarea>
          <br></br>
        </div>
        <div style={divButtons}>
          <button style={styleButton} onClick={() => {
              handleClosePmv();
              handleSavePmv();
            }}>
            Guardar
          </button>
        </div>
      </Modal>
      <div className="grid-courses"> {body} </div>
    </div>
  );
}


const styleButton = {
  "padding": "5px",
  "margin": "5px",
  "height": "min(100%, 60px)",
  "border-radius": "50px",
  "font-weight": "bold",
  "text-transform": "uppercase",
  "background-color": "#0c3787",
  "color": "#fff",
  "border": "none"
}

const divButtons = {
  "display": "grid",
  "grid-template-columns": "repeat(auto-fit, min(100%, 120px))",
  "gap": "10px",
  "overflow-x": "hidden",
  "justify-content": "end",
  "height": "23%",
  "align-items": "center"
}

const divQuestions = {
  "display": "grid",
  "grid-template-columns": "min(100%, 400px))",
  "grid-template-rows": "60px",
  "gap": "10px",
  "overflow-x": "hidden",
  "justify-content": "center",
  "align-items": "center",
  "height": "77%"
}

const divInput = {
  "height": "60px"
}

const divPMV = {
  "display": "grid",
  "grid-template-columns": "min(100%, 400px))",
  //"grid-template-rows": "10px",
  "gap": "10px",
  "overflow-x": "hidden",
  "justify-content": "center",
  "align-items": "center",
  "height": "77%"
}