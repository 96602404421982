

import React from "react";

const UserProfile = () => {
  return (
    <div className="perfil">
      <h1>Perfil de Usuario</h1>
      <div>
        <h2>Nombre de Usuario</h2>
        <p>Email: example@example.com</p>
        <p>Fecha De Nacimiento</p>
        <p>Ubicación: Ciudad, País</p>
      </div>
    </div>
  );
};

export default UserProfile;
