import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function  Square(props){
    props = props.course;
    const navigate = useNavigate();
    let id = props.id;
    const [over, setOver] = useState(false)
    function handleClick(props){
        localStorage.setItem('course_id', props.id);
        navigate('/leccion')
    }
    function pointerOver(){
        setOver(true);
    }
    function pointerLeave(){
        setOver(false);
    }
    let style = over ? {
        "color" :"#0c3787",
        "background-color": "#01bf2d",
    } : {
        "color": "#0c3787",
        "background-color": "#fbc00a",
    }

    return (
        <div className="gray-square" onPointerEnter={pointerOver} onPointerLeave={pointerLeave} style={style} onClick={()=>{handleClick(props)}}>
            <div className="title-gray-square">{props.name}</div>
            <div className="description-gray-square">{props.description}</div>
            <div className="progress-gray-square">{props.progress}/{props.total}</div>
            <div>
                
            </div>
        </div>
        
    )
}