
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import NavBar from './components/navbar'
import Login from './components/login';
import Courses from './components/courses';
import Registerpage from './components/Registerpage';
import Reporte from './components/reporte';
import RegisterForm from './components/RegisterForm';
import Usuariospremium from './components/usuariospremium'
import Cursosadmin from './components/cursosadmin';
import LandingPage from './components/landing'
import Lecciones from './components/lecciones'
import Leccion from './components/leccion'
import QuizPage2 from './components/Quizpage2'
import UserProfile from './components/UserProfile';
import Recursos from './components/recursos2'
import Meditaciones from './components/meditaciones';
import Quiz1 from './components/Quiz1'
import Quiz2 from './components/Quiz2'
import Quiz3 from './components/Quiz3'
import Balloons from './components/balloons'
import Blockers from './components/blockers'
import Editor from './components/editor';
import CoursesBasic from './components/courses_basic'
import Landing3 from './components/landing3'
import Dashboard from './components/dashboard';
import MiScore from './components/MiScore';
import Mci from './components/mci';
import Journal from './components/journal';
import { DataProvider } from './components/DataContext';




function App() {
  const router = createBrowserRouter([
    {
      element: (
        <>
          <NavBar />
          <Outlet />
        </>
      ),
      children: [
        {
          path: "/",
          element: <LandingPage/>,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/registro",
          element: <RegisterForm />,
        },
        {
          path: "/reporteUsuarios",
          element: <Reporte/>,
        },
        {
          path: "/Registerpage",
          element: <Registerpage/>,
        },
        {

          path: "/courses",
          element: <Courses />,
        },
        {

          path: "/courses_basic",
          element: <CoursesBasic />,
        },
        {
          path: "/usuariospremium",
          element: <Usuariospremium/>,

        },
        {
          path: "/cursosadmin",
          element: <Cursosadmin/>,
        },
        {
          path: "/lecciones",
          element: <Lecciones/>,
        },
        {
          path: "/leccion",
          element: <Leccion/>,
        },
        {
          path: "/Score-Card",
          element: <QuizPage2/>,
        },
        {
          path: "/UserProfile",
          element: <UserProfile/>,
        },
        {
          path: "/Recursos",
          element: <Recursos/>,
        },
        {
          path: "/Meditaciones",
          element: <Meditaciones/>,
        },
        {
          path: "/Quiz",
          element: <Quiz1/>,
        },
        {
          path: "/Quiz2",
          element: <Quiz2/>,
        },
        {
          path: "/Quiz3",
          element: <Quiz3/>,
        },
        {
          path: "/balloons",
          element: <Balloons/>,
        },
        {
          path: "/blockers",
          element: <Blockers/>,
        },
        {
          path: "/editor",
          element: <Editor/>,
        },
        {
          path: "/landing2",
          element: <Landing3/>,
        },
        {
          path: "/Dashboard",
          element: <Dashboard/>,
        },
        {
          path: "/MyScore",
          element: <MiScore/>,
        },
        {
          path: "/mci",
          element: <Mci/>,
        },
        {
          path: "/journal",
          element: <Journal/>,
        },
        
      ],
    },
  ]);
  
  return (
   
      <DataProvider>
        <RouterProvider router={router}>
        </RouterProvider>
      </DataProvider>
   
  );
}

export default App;
