import React from 'react';
import libro1 from '../recursos/ebooks/10claves.pdf'
import libro2 from '../recursos/ebooks/Caso-Johny-Bravo.pdf'
import libro3 from '../recursos/ebooks/Caso-TIPOS-DE-Feedback.pdf'
import libro4 from '../recursos/ebooks/Caso-Verde-Limpio.pdf'
import libro5 from '../recursos/ebooks/Liderazgo.pdf'
import libro6 from '../recursos/ebooks/actitud.pdf'
import libro7 from '../recursos/ebooks/Cuaderno-de-notas.docx'
import libro8 from '../recursos/ebooks/FeedbackLiderazgo.pdf'
import libro9 from '../recursos/ebooks/FormatoFF.pdf'
import libro10 from '../recursos/ebooks/quizautoescucha.pdf'
import libro11 from '../recursos/ebooks/Exito-real.pdf'
import libro12 from '../recursos/ebooks/Planeacion.pdf'
import libro13 from '../recursos/ebooks/BASES-DEL-TRABAJO-EN-EQUIPO.pdf'
import libro14 from '../recursos/ebooks/CULTURA-ORGANIZACIONAL.pdf'
import libro15 from '../recursos/ebooks/KAIZEN.pdf'
import libro16 from '../recursos/ebooks/LEADER-SUMMARIES_SEÑALES.pdf'
import libro17 from '../recursos/ebooks/LEADER-SUMMARIES-AYÚDALOS-A-CRECER.pdf'
import libro18 from '../recursos/ebooks/LIDERAZGO-STRONG.pdf'
import libro19 from '../recursos/ebooks/OBJETIVO-COMÚN.pdf'
import libro20 from '../recursos/ebooks/DESARROLLE-EL-LÍDER.pdf'

import cuaderno1 from '../recursos/Cuadernos/Cuaderno-Administracióndeltiempo.pdf'
import cuaderno2 from '../recursos/Cuadernos/Cuaderno-Resistencia.pdf'
import cuaderno3 from '../recursos/Cuadernos/Cuaderno-MetasyPlaneación.pdf'
import cuaderno4 from '../recursos/Cuadernos/Cuaderno-Meditaciones.pdf'
import cuaderno5 from '../recursos/Cuadernos/Cuaderno-Habitos.pdf'
import cuaderno6 from '../recursos/Cuadernos/Cuaderno-escucha.pdf'
import cuaderno7 from '../recursos/Cuadernos/Cuaderno-Emociones.pdf'
import cuaderno8 from '../recursos/Cuadernos/Cuaderno-de-trabajo-Bloque.docx'
import cuaderno9 from '../recursos/Cuadernos/Cuaderno-Creencias.pdf'
import cuaderno10 from '../recursos/Cuadernos/Cuaderno-bloque-2-actitud.pdf'

import test1 from '../recursos/Cuadernos/PRUEBA DISC.xlsx'
import test2 from '../recursos/Cuadernos/Test-Estilos de-Liderazgo.xlsx'

import audioimagen from '../recursos/imagenes/audio-imagen.jpeg'

import imagen1 from '../recursos/imagenes/10claves.jpg'
import imagen2 from '../recursos/imagenes/johny-bravo.jpg'
import imagen3 from '../recursos/imagenes/Feedback.jpg'
import imagen4 from '../recursos/imagenes/verde-limpio.jpg'
import imagen5 from '../recursos/imagenes/liderazgoinstante.jpg'
import imagen6 from '../recursos/imagenes/actitud.jpg'
import imagen7 from '../recursos/imagenes/10claves.jpg'
import imagen8 from '../recursos/imagenes/feed-back.jpg'
import imagen9 from '../recursos/imagenes/10claves.jpg'
import imagen10 from '../recursos/imagenes/autoinventario.jpg'
import imagen11 from '../recursos/imagenes/exito-real.jpg'
import imagen12 from '../recursos/imagenes/pleaneacion.jpg'
import imagen13 from '../recursos/imagenes/Trabajo-en-equipo.jpg'
import imagen14 from '../recursos/imagenes/cultura-organizacional.jpg'
import imagen15 from '../recursos/imagenes/Kaizen.jpg'
import imagen16 from '../recursos/imagenes/Leader-summaries.jpg'
import imagen17 from '../recursos/imagenes/Leader-summaries.jpg'
import imagen18 from '../recursos/imagenes/Liderazgo-strong.jpg'
import imagen19 from '../recursos/imagenes/objetivo-comun.jpg'
import imagen20 from '../recursos/imagenes/desarrolle-el-lider.jpg'


function Downloads() {
  return (
    <div className="container-audios-books">
      <h1 className='h2-audio'>Descargas E-books y Cuadernos</h1>

      <div className="ebooks">
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src= {imagen1}
                className="img-top"
                alt="Ebook 1"
              />
              <div className="card-body">
                <h1 className="card-title">10 Claves Que Todo Líder Debe Tener En Cuenta Para Influir Positivamente</h1>
                <a href={libro1}   className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>

          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen2}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
              <h1 className="card-title">Curso Leader Mind: Caso Johny Bravo</h1>
                <a href={libro2} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen3}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
              <h1 className="card-title">Curso Leader Mind: Caso Feedback</h1>
                <a href={libro3} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen4}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
              <h1 className="card-title">Curso Leader Mind: Caso Verde Y Limpio</h1>
                <a href={libro4} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen5}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
              <h1 className="card-title">Liderazgo Al Instante Guía Practica</h1>
                <a href={libro5} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen6}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
              <h1 className="card-title">La Actitud Lo Es Todo</h1>
                <a href={libro6} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen7}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
              <h1 className="card-title">Cuaderno De Notas Liderazgo En Tiempo De Crisis</h1>
                <a href={libro7} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen8}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">El Feedback Y El Feedforward En El Liderazgo</h1>
                <a href={libro8} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen9}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
              <h1 className="card-title">Formato Feedforward</h1>
                <a href={libro9} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen10}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Quiz Auto Inventario De Escucha</h1>
                <a href={libro10} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div>
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen11}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cómo Ser Un Éxito Real</h1>
                <a href={libro11} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen12}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Planeación, Apoyo A La Toma De Riesgos E Involucramiento</h1>
                <a href={libro12} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
            <br/>
            <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen13}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Bases Del Trabajo En Equipo</h1>
                <a href={libro13} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen14}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cultura Organizacional</h1>
                <a href={libro14} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen15}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Metodología KAIZEN para la resolución de procesos</h1>
                <a href={libro15} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen16}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Leader Summaries SEÑALES</h1>
                <a href={libro16} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen17}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Leader Summaries Ayudalos A Crecer</h1>
                <a href={libro17} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen18}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Liderazgo Strong</h1>
                <a href={libro18} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen19}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Objetivo Común Y Reglas Del Juego</h1>
                <a href={libro19} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={imagen20}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Desarrolle El Lider Que Lleva Dentro</h1>
                <a href={libro20} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Administración Del Tiempo</h1>
                <a href={cuaderno1} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Bloque Resistencia</h1>
                <a href={cuaderno2} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Metas Y Planes</h1>
                <a href={cuaderno3} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Meditaciones</h1>
                <a href={cuaderno4} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Habitos</h1>
                <a href={cuaderno5} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Escucha</h1>
                <a href={cuaderno6} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Emociones</h1>
                <a href={cuaderno7} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno De Trabajo Bloque 1</h1>
                <a href={cuaderno8} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno Creencias</h1>
                <a href={cuaderno9} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Cuaderno De Trabajo Bloque 2 </h1>
                <a href={cuaderno10} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Test Estilos de Liderazgo</h1>
                <a href={test2} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          <br/>
          <div className="col-sm-6 col-md-4">
            <div className="card1">
              <img
                src={audioimagen}
                className="img-top"
                alt="Ebook 2"
              />
              <div className="card-body">
                <h1 className="card-title">Prueba Disc </h1>
                <a href={test1} className="btn-p">
                  Descargar
                </a>
              </div>
            </div>
          </div> 
          </div>
        </div>
      </div>
      </div>
        );
  }

export default Downloads;
