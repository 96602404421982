import { useState } from "react"
import { useNavigate } from "react-router-dom";
import ipGlobal from '../config/global'
import icono1 from '../static/icono1.webp'
import icono2 from '../static/icono2.webp'
import icono3 from '../static/icono3.webp'
import icono4 from '../static/icono4.webp'
import icono5 from '../static/icono5.webp'
import icono6 from '../static/icono6.webp'
import icono7 from '../static/icono7.webp'

export default function Login(){
    const [username, setUsername] = useState('');
    const [passwd, setPasswd] = useState('');
    const navigate = useNavigate();
  


    const loginImage = <div className="image-login-div">
        <div className="images-login">
            <img src={icono1}/>
            
        </div>
        <div className="images-login">
        <img src={icono2}/>
        </div>
        <div className="images-login">
        <img src={icono3}/>
        </div>
        <div className="images-login">
        <img src={icono4}/>
        </div>
        <div className="images-login">
        <img src={icono5}/>
        </div>
        <div className="images-login">
        <img src={icono6}/>
        </div>
        <div className="images-login">
        <img src={icono7}/>
        </div>
    </div>

    const submmit = () => {
        if (username.target !== undefined && passwd.target !== undefined){
            const usernameRequest = username.target.value;
            const passRequest = passwd.target.value;
            const request = {
                email: usernameRequest,
                password: passRequest
            }
            console.log("Click", request);
            fetch(`${ipGlobal}${"login"}`, {
                method: 'POST',
                body: JSON.stringify(request), 
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
            .then((data) => {
              console.log(data);
              if (data.messages) {
                alert(data.messages);
              } else {
                localStorage.setItem('token', data.token);
                localStorage.setItem('epa', data.epa);
                localStorage.setItem('message', true);
                localStorage.setItem('leader', data.leader);
                localStorage.setItem('pmv', data.pmv);
                if (data.admin){
                    navigate("/reporteUsuarios");
                } else {
                    navigate("/courses");
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        else {
            alert("Favor de llenar los campos")
        }
        
    }

    return(
        <div className='login-page'>
            <div className='login-base' >
                <h2 className="label-login">Login</h2>
                <div className='login-content'>
                    <label>
                        E-mail:
                    </label>
                    <input type="text" className="log-cont" name="name" onChange={setUsername}/>
                    <label>
                        Password:
                    </label>
                    <input type="password" className="log-cont" name="passwd" onChange={setPasswd}/>
                    <button className="btn" onClick={submmit}>Ingresar </button>
                         

                </div>
            </div>

            <div className="iconos-login">
            <h1>Objetivos</h1>
            {loginImage}
            </div>
            
            
            
            
        </div>
    )
}