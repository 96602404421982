import { useEffect, useState } from "react";
import Modal from 'react-modal';
import blockerImage from '../static/blocker.png'
import ipGlobal from '../config/global'
import { useNavigate } from "react-router-dom";

export default function Blockers(){
    const navigate = useNavigate();
    const [blockers, setBlockers] = useState([])
    const [id, setId] = useState()
    const [name, setName] = useState("")
    const [question1, setQuestion1] = useState("")
    const [question2, setQuestion2] = useState("")
    const [question3, setQuestion3] = useState("")
    const [question4, setQuestion4] = useState("")
    const [question5, setQuestion5] = useState("")
    const [question6, setQuestion6] = useState("")
    const [question7, setQuestion7] = useState("")
    const [question8, setQuestion8] = useState("")
    const [question9, setQuestion9] = useState("")
    const [question10, setQuestion10] = useState("")
    const [question11, setQuestion11] = useState("")
    const [open, setOpen] = useState(false)
    
    const handleName = event => {
        setName(event.target.value);
    };
    const handleQuestion1 = event => {
        setQuestion1(event.target.value);
    };
    const handleQuestion2 = event => {
        setQuestion2(event.target.value);
    };
    const handleQuestion3 = event => {
        setQuestion3(event.target.value);
    };
    const handleQuestion4 = event => {
        setQuestion4(event.target.value);
    };
    const handleQuestion5 = event => {
        setQuestion5(event.target.value);
    };
    const handleQuestion6 = event => {
        setQuestion6(event.target.value);
    };
    const handleQuestion7 = event => {
        setQuestion7(event.target.value);
    };
    const handleQuestion8 = event => {
        setQuestion8(event.target.value);
    };
    const handleQuestion9 = event => {
        setQuestion9(event.target.value);
    };
    const handleQuestion10 = event => {
        setQuestion10(event.target.value);
    };
    const handleQuestion11 = event => {
        setQuestion11(event.target.value);
    };
    useEffect(() => {
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"blockers"}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
          console.log(data.blockers)
          setBlockers(data.blockers)
        })
        .catch((err) => {
          console.log(err);
          navigate("/login")
        });
    }, [open, id, name]);

    function handleSave(){
        let form = handleGenerateForm()
        if(id){
            handleModify(form)
        } else {
            handleCreate(form)
        }
        handleClose()
    }

    function handleModify(form){
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"blocker/"}${id}`, {
            method: 'PUT',
            body: JSON.stringify(form), 
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
        console.log(data)
        handleClose()
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
    }
    function handleCreate(form){
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"blockers"}`, {
            method: 'POST',
            body: JSON.stringify(form), 
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
        console.log(data)
        handleClose()
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
    }
    function handleDelete(){
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"blocker/"}${id}`, {
            method: 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
        console.log(data)
        handleClose()
        })
        .catch((err) => {
        console.log(err);
        navigate("/login")
        });
    }
    function handleClose(){
        setOpen(false)
        handleCleanQuestions()
    }
    function handleOpen(){
        setOpen(true)
    }
    function handleOpenEdit(blocker){
        console.log(blocker)
        setId(blocker.id)
        setName(blocker.name)
        setQuestion1(blocker.question1)
        setQuestion2(blocker.question2)
        setQuestion3(blocker.question3)
        setQuestion4(blocker.question4)
        setQuestion5(blocker.question5)
        setQuestion6(blocker.question6)
        setQuestion7(blocker.question7)
        setQuestion8(blocker.question8)
        setQuestion9(blocker.question9)
        setQuestion10(blocker.question10)
        setQuestion11(blocker.question11)
        handleOpen()
    }
    function handleCleanQuestions(){
        setName("")
        setQuestion1("")
        setQuestion2("")
        setQuestion3("")
        setQuestion4("")
        setQuestion5("")
        setQuestion6("")
        setQuestion7("")
        setQuestion8("")
        setQuestion9("")
        setQuestion10("")
        setId(null)
    }
    function handleGenerateForm(){
        return {
            name: name,
            question1: question1,
            question2: question2,
            question3: question3,
            question4: question4,
            question5: question5,
            question6: question6,
            question7: question7,
            question8: question8,
            question9: question9,
            question10: question10,
            question11: question11
        }
    }

    return (
        <div>
            <div>
                <button style={styleButton} onClick={handleOpen}>Agregar Blocker</button>
            </div>
            <div style={divBlockers}>
                {blockers.map((blocker)=><div style={divNameBlocker}><img src={blockerImage} height={'200px'} onClick={()=>handleOpenEdit(blocker)}/>{blocker.name}</div>)}
            </div>
            
            <Modal
                isOpen={open}
                contentLabel="Example Modal"
            >
                <div style={divQuestions}>
                    ¿Cúal es el nombre del blocker?
                    <textarea style={divInput} value={name} onChange={handleName}></textarea>
                    ¿Quién es la persona con la que tienes el problema? (No hay colaboración, la comunicación no está fluyendo, sientes que te está bloqueando o hay algún tema o comportamiento que te genera malestar)
                    <textarea style={divInput} value={question1} onChange={handleQuestion1}></textarea>
                    Concretamente ¿Cuál es el problema que estás experimentando con esta persona?
                    <textarea style={divInput} value={question2} onChange={handleQuestion2}></textarea>
                    Concretamente , ¿Por qué te está molestando está situación?
                    <textarea style={divInput} value={question3} onChange={handleQuestion3}></textarea>
                    ¿Hubo algún evento o situación específica que desencadenó este problema?
                    <textarea style={divInput} value={question4} onChange={handleQuestion4}></textarea>
                    ¿Qué papel crees que estás desempeñando en este conflicto? ¿Hay algo que puedas hacer para cambiarlo?
                    <textarea style={divInput} value={question5} onChange={handleQuestion5}></textarea>
                    ¿Has intentado comunicarte directamente con esa persona sobre el problema o tener una conversación crucial?
                    <textarea style={divInput} value={question6} onChange={handleQuestion6}></textarea>
                    ¿Es necesario tener una conversación crucial con esta persona? (si la respuesta es no pasa a la pregunta número 9)
                    <textarea style={divInput} value={question7} onChange={handleQuestion7}></textarea>
                    ¿Qué es lo que le tienes que decir ¿Qué resultado te gustaría ver derivado de esta conversación? ¿Cuál sería un escenario ideal para ti?
                    <textarea style={divInput} value={question8} onChange={handleQuestion8}></textarea>
                    ¿Estás dispuesto/a a comprometerte o hacer concesiones para resolver este problema?
                    <textarea style={divInput} value={question9} onChange={handleQuestion9}></textarea>
                    ¿Qué solución o soluciones puedes buscar tu?  (desde tu trinchera)
                    <textarea style={divInput} value={question10} onChange={handleQuestion10}></textarea>
                    ¿Cómo te sientes? ( 1 al 10 ) Repite este cuestionario si tu calificación es mayor a 3
                    <textarea style={divInput} value={question11} onChange={handleQuestion11}></textarea>
                    Si no encontraste la respuesta que esperabas o necesitabas, entonces haz lo siguiente:<br></br><br></br>
                    - Haz una pausa y trata de relajarte.<br></br>
                    - Inhala y exhala 3 veces lentamente.<br></br>
                    - Hazte consciente de tus pensamientos y de tu actitud, evita los señalamientos, las excusas, y las justificaciones. Trata de hacerte responsable, de ser autocrítico, trata de ir sobre la línea.<br></br>
                    - Recuerda que estar <b>SOBRE LA LÍNEA</b> Es buscar soluciones, colaborar y contruir en pro de tu persona, equipo, empresa y relación.<br></br>
                    <b>STOP</b>
                    - Es muy importante en ocasiones como estas hacer una pausa, repetir en nuestra cabeza la palabra STOP y cuidar nuestro diálogo interno para no hacer los problemas más grandes (siéntate y cierra tus ojos).<br></br>
                </div>
                <div style = {divButtons}>
                    <button style={styleButton} onClick={handleClose}>Cerrar</button>
                    <button style={styleButton} onClick={handleSave}>Guardar</button>
                    {id ? <button style={styleButton} onClick={handleDelete}>Reventar blocker</button> : null}
                </div>
            </Modal>
        </div>
    )
}

const styleButton = {
    "padding": "5px",
    "margin": "5px",
    "height": "min(100%, 60px)",
    "border-radius": "50px",
    "font-weight": "bold",
    "text-transform": "uppercase",
    "background-color": "#0c3787",
    "color": "#fff",
    "border": "none"
}

const divButtons = {
    "display": "grid",
    "grid-template-columns": "repeat(auto-fit, min(100%, 120px))",
    "gap": "10px",
    "overflow-x": "hidden",
    "justify-content": "end",
    "height": "23%",
    "align-items": "center"
}

const divBlockers = {
    "display": "grid",
    "grid-template-columns": "repeat(auto-fit, min(100%, 200px))",
    "gap": "10px",
    "overflow-x": "hidden",
    "justify-content": "center",
    "align-items": "center",
}

const divNameBlocker = {
    "display": "grid",
    "grid-template-columns": "200px",
    "grid-template-rows": "200px",
    "gap": "5px",
    "overflow-x": "hidden",
    "justify-content": "center",
    "align-items": "center",
    "font-weight": "bold",
    "text-transform": "uppercase",
}

const divQuestions = {
    "display": "grid",
    "grid-template-columns": "min(100%, 400px))",
    "grid-template-rows": "60px",
    "gap": "10px",
    "overflow-x": "hidden",
    "justify-content": "center",
    "align-items": "center",
    "height": "77%"
}

const divInput = {
    "height": "60px"
}