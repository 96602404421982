import React, { useState } from 'react';
import ModalCursos from './modalcursos';

function MyComponent(props) {
  const [showModal, setShowModal] = useState(props.show);

  const handleCloseModal = () => setShowModal(false);
  

  return (
    <>
      <ModalCursos show={showModal} onClose={handleCloseModal} message={props.message} 
        author={props.author} title={props.title}/>
    </>
  );
}

export default MyComponent;
