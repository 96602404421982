import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ipGlobal from '../config/global'
import Modal from 'react-modal';

function Usuarios() {
  const [usuarios, setUsuarios] = useState ();
  const [modalOpen, setModalOpen] = useState(false)
  const [id, setId] = useState(false)
 

  useEffect(() => {
      if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
      fetch(`${ipGlobal}${"users"}`, {
          method: 'GET',
          headers:{
              'Content-Type': 'application/json',
              'x-access-token': localStorage.getItem('token')
          }
      }).then((response) => response.json())
      .then((data) => {
        console.log(data.users)
        setUsuarios(data.users)
      })
      .catch((err) => {
        console.log(err);
        navigate("/login")
      });
    }, [modalOpen]);
    
  function doRow(usuario){
    return(
      <tbody>
        <tr> 
          <td>{usuario.id}</td>
          <td>{usuario.email}</td>
          <td><div onClick={()=> openEditUser(usuario)}>{usuario.fullname}</div></td>
          <td>{usuario.phone}</td>
          <td>{usuario.company}</td>
          <td>{usuario.position}</td>
          <td>{usuario.birthdate}</td>
          <td>{usuario.country}</td>
          <td>{usuario.state}</td>
          <td>{usuario.city}</td>
          <td>{usuario.score}</td>
          <td>{usuario.admin ? "si": "no"}</td>
          <td>{usuario.created_at}</td>
          <td>{usuario.start_date}</td>
          <td>{usuario.end_date}</td>
        </tr>
      </tbody>
    )
  }

  const navigate = useNavigate ();

  function handleCloseModal(){
    setModalOpen(false)
  }

  function getElementValue(id) {
    const element = document.getElementById(id);
    return element ? element.value : "";
  }

  function getForm(){
    return {
      id: id,
      email: getElementValue("email"),
      fullname: getElementValue("fullname"),
      phone: getElementValue("phone"),
      position: getElementValue("position"),
      company: getElementValue("company"),
      birthdate: getElementValue("birthdate"),
      country: getElementValue("country"),
      state: getElementValue("state"),
      city: getElementValue("city"),
      password: getElementValue("password"),
      admin: getElementValue("admin")
    }
  }

  function setForm(data){
      console.log(data)
      document.getElementById("email").value = data.email
      document.getElementById("fullname").value = data.fullname
      document.getElementById("phone").value = data.phone
      document.getElementById("position").value = data.position
      document.getElementById("company").value = data.company
      document.getElementById("birthdate").value = data.birthdate
      document.getElementById("country").value = data.country
      document.getElementById("state").value = data.state
      document.getElementById("city").value = data.city
      document.getElementById("admin").value = data.admin
  }

  function handleSave(){
    const form = getForm()
    console.log(form)
  }

  function handleDelete(){
    console.log(id)
  }

  function openNewUser(){
    setId(null)
    setModalOpen(true)
  }

  function openEditUser(data){
    setId(data.id)
    setModalOpen(true)
    setTimeout(() => {setForm(data)}, 100)
  }

  return (
  <div style={{overflowX : 'auto'}}>
    <div>
    <Modal isOpen={modalOpen} contentLabel="Example Modal">
        <div style={divQuestions}>
          <h1>Usuario</h1>
          <br />
          E-mail:
          <input style={divInput} id="email"/>
          <br />
          Nombre completo:
          <input style={divInput} id="fullname"/>
          <br />
          Teléfono:
          <input style={divInput} id="phone"/>
          <br />
          Posición:
          <input style={divInput} id="position"/>
          <br />
          Compañía:
          <input style={divInput} id="company"/>
          <br />
          Fecha de nacimiento:
          <input style={divInput} id="birthdate" type='date'/>
          <br />
          País:
          <input style={divInput} id="country"/>
          <br />
          Estado:
          <input style={divInput} id="state"/>
          <br />
          Ciudad:
          <input style={divInput} id="city"/>
          <br />
          Contraseña:
          <input style={divInput} id="password"/>
          <br />
          Admin:
          <select id="admin">
            <option value={false}>No</option>
            <option value={true}>Si</option>
          </select>
        </div>
        <div style={divButtons}>
        {id ? <button
            style={styleButton}
            onClick={() => {
              handleDelete();
            }}
          >
            Eliminar
          </button>: <div></div>}
          <button
            style={styleButton}
            onClick={() => {
              handleSave();
            }}
          >
            Guardar
          </button>
          <button
            style={styleButton}
            onClick={() => {
              handleCloseModal();
            }}
          >
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
    <div className='header'> Usuarios Registrados </div>
    <div className='btn-crear'>
      <button  className='btn' onClick={() => openNewUser()} type='submit'>crear nuevo usuario</button>
    </div>
    <table className='users'>
      <thead>
        <tr>
          <th>Id</th>
          <th>Email</th>
          <th>Nombre</th> 
          <th>Telefono</th>
          <th>Empresa</th>
          <th>Puesto</th>
          <th>Fecha de Nacimiento</th>
          <th>Pais</th>
          <th>Estado</th>
          <th>Ciudad</th>
          <th>Puntaje</th>
          <th>Admin</th>
          <th>Fecha de Creacion</th>
          <th>Inicio Premium</th>
          <th>Fin Premium</th>
        </tr>
      </thead>
      {
        usuarios ? usuarios.map(doRow) : null
      }
    </table>
  </div>
  )
}

export default Usuarios

const divButtons = {
  "display": "grid",
  "grid-template-columns": "repeat(auto-fit, min(100%, 120px))",
  "gap": "10px",
  "overflow-x": "hidden",
  "justify-content": "end",
  "height": "23%",
  "align-items": "center"
}
const styleButton = {
  "padding": "5px",
  "margin": "5px",
  "height": "min(100%, 60px)",
  "border-radius": "50px",
  "font-weight": "bold",
  "text-transform": "uppercase",
  "background-color": "#0c3787",
  "color": "#fff",
  "border": "none"
}
const divQuestions = {
  "display": "grid",
  "grid-template-columns": "min(100%, 400px))",
  "grid-template-rows": "60px",
  "gap": "10px",
  "overflow-x": "hidden",
  "justify-content": "center",
  "align-items": "center",
  "height": "77%"
}

const divInput = {
  "height": "26px"
}