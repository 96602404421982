import React, { useState, useEffect } from 'react';
import alpura from '../static/Alpura-logo.png'
import anahuac from '../static/Anahuac-logo.png'
import casareyna from '../static/casareyna-logo.png'
import granbodega from '../static/GranBodega-logo.png'
import heneiken from '../static/Heneiken-logo.png'
import inbursa from '../static/Inbursa-logo.png'
import deer from '../static/johndeere-logo.png'

const images = [
  alpura,
  anahuac,
  casareyna,
  granbodega,
  heneiken,
  inbursa,
  deer
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(currentIndex => (currentIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <img
          id='img'
          key={index}
          src={image}
          className={index === currentIndex ? 'active' : ''}
        />
      ))}
    </div>
  );
};

export default Carousel;

