import React from 'react'



const modalpremium = props => {
    if (!props.show){
        return null
    }

    return (
        <div className='modal'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h4 className='modal-title'>selecionar usuario</h4>
                </div>
                <div className='modal-body'>
                    agregar premium 
                    <br/>
                    <br/>
                    juan arnulfo torres   <input type="checkbox" className='modal-check' ></input>
                </div>
                <div className='modal-footer'>
                  
                    
                   
                    <button onClick={props.onClose} className='button-modal'>close</button>
                </div>
            </div>

        </div>
    )

}

export default modalpremium