import React from 'react';
import Carousel from './carousel';
import { useNavigate } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import pepe1 from '../static/pepe1.jpeg'
import pepe2 from '../static/pepe2.jpeg'
import pepe3 from '../static/pepe3.jpeg'
import icono1 from '../static/icono1.webp'
import icono2 from '../static/icono2.webp'
import icono3 from '../static/icono3.webp'
import icono4 from '../static/icono4.webp'
import icono5 from '../static/icono5.webp'
import icono6 from '../static/icono6.webp'
import icono7 from '../static/icono7.webp'
import icono8 from '../static/icono8.webp'
import icono9 from '../static/icono9.webp'


const LandingPage = () => {
    const navigate = useNavigate();
    const navigateCourses = () => {
      navigate("/registro")
    }

  return (
    <div className="container-landing">
      <div className="hero">
        <h1>Aprende Algo Nuevo Hoy!!</h1>
        <p>Descubre cursos en línea de calidad impartidos por Pepe Loyo. Ya sea que desees mejorar tus habilidades o explorar una nueva carrera, tenemos algo para ti.</p>
      </div>
      <div className="features" id="cursos">
        <h2>Cursos Destacados</h2>
        <div className="cards-container">
          <div className="card">
            <img src={pepe1} alt="Curso 1" height={'200px'}/>
            <h2>Speaker Master</h2>
            <h3>Aprende a Hablar en Público</h3>  
          </div>
          <div className="card">
            <img src={pepe2} alt="Curso 2" height={'200px'}/>
            <h2>MasterClass</h2>
            <h3>Principios de Crecimiento y Liderazgo</h3>
            
          </div>
          <div className="card">
            <img src={pepe3} alt="Curso 3" height={'200px'}/>
            <h2>Leader Mind</h2>
            <h3>Curso Intensivo de Liderazgo Efectivo</h3>
          </div>
        </div>
        <br/>
        <div className='nuestra-plataforma'>
          <div className='title-nuestra'><h1 style={{color:'#29d15b'}}>¿Que Aprenderás En Nuestra Plataforma?</h1></div>
         <div className='container-plataforma'> 
         <div><img src={icono1} alt="Curso 2" height={'150px'}/>
         <h1>Manejo de estrés e inteligencia Emocional</h1></div>
         <div> <img src={icono2} alt="Curso 2" height={'150px'}/>
         <h1>Trabajo en Equipo</h1> </div>
         <div><img src={icono3} alt="Curso 2" height={'150px'}/>
         <h1>cooperacion Mundial</h1></div>
         <div><img src={icono4} alt="Curso 2" height={'150px'}/>
         <h1>comunicacion efectiva</h1></div>
         <div><img src={icono8} alt="Curso 2" height={'150px'}/>
         <h1>Lidiar con el Burnout</h1></div>
         <div>  <img src={icono6} alt="Curso 2" height={'150px'}/>
         <h1>Planeacion para un alto Rendimiento</h1></div>  
         </div>
        
        </div>
      </div>
      <div className="about" id="nosotros" style={{backgroundColor:"#0c3787"}}>
        <h1 style={{color:"#29d15b"}}>Nosotros</h1>
        <br/>
        <h3>¿QUE ES SLL?</h3>
        <p>
          <br/>
            Plataforma que te ofrece experiencias digitales
            on demand para ayudarte a desarrollarte como
            líder, crecer como persona y crear equipos de
            alto rendimiento.
            <li> Es necesario desarrollarnos y trabajar en
              nosotros mismos.</li>
            <li> Convertirnos en personas que impactan y
              trascienden a través de sus acciones.</li>
            <li> Adaptándonos a los retos de tiempos,
              digitales y económicos del mundo actual.</li>
        </p>
            <br/>
            <br/>
      </div>
      <div className="testimonials-container" id="testimonios">
           <div className="testimonial-card">
           <h1>Testimonios</h1>
           <br/>
           <div>
           <iframe width="560" height="315" src="https://www.youtube.com/embed/zj_IOcCh5tQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
           <h2 >Claudia Beatriz</h2>
              <br/>
             <h3>Jefa de Producción Heineken</h3>
           </div>
           <br/>
           <div className="testimonial-card">
              
             <br/>
             <iframe width="560" height="315" src="https://www.youtube.com/embed/9EPwy6E7y6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
             <h2>Jacinto Vargas</h2>
            <br/>
             <h3>TP Expertos En Seguridad</h3>
             <br/>
             <br/>
            </div>
            <div className="testimonial-card">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/14D12SrJdhQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            
            <h2>Santiago Solana</h2>
            <br/>
            <h3>Origen Central</h3>
            </div>
            </div>
        </div>
      <div className='carusel' >
        <h1>Empresas con las que trabajo de la mano! </h1>
        <div>
            <Carousel/>
        </div>
       
        
        
      </div>
      <footer className='foot'>
      <div className='sociales'>
            <h1>Sigueme En Redes Sociales</h1>
            <br/>
            <SocialIcon bgColor="white" style={{ height: 70, width: 70 }} url="https://www.linkedin.com/in/jos%C3%A9-luis-loyo-bernardi-3b948bb0" />
            <SocialIcon  bgColor="white" style={{ height: 70, width: 70 }} url="https://www.facebook.com/coachpepeloyobernardi"/>
            <br/>
            <br/>
            <p>© 2023 Sobre La Línea. Todos los derechos reservados.</p>
        </div>
        
      </footer>
    </div>
  );
};

export default LandingPage;


