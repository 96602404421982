import { useState, useEffect } from 'react';
import Zidebar from './sidebar';
import { useNavigate } from "react-router-dom";
import ipGlobal from '../config/global'


function MainContent(props) {
  return (
    <div className="main-content">
        
        <div className="video-player">
          <iframe width={"100%"} height={"100%"} src={props.video} title="YouTube video player" allowFullScreen></iframe>
        </div>
    </div>
  );
}

export default function Leccion() {
  const navigate = useNavigate();
  const [leassons, setLeassons] = useState([])
  const [video, setVideo] = useState()
  const [title, setTitle] = useState()
  const [quiz, setQuiz] = useState({
    "name": "Ejemplo de quiz",
    "questions": [
      {"question": "Ejemplo pregunta", "type": "text"},
      {"question": "Ejemplo pregunta seleccionable", "type": "selectable", 
      "selections":[
        {"name": "uno", "value": 1},
        {"name": "dos", "value": "A ver"}
      ]}
    ]
  })
  useEffect(() => {
    if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
    fetch(`${ipGlobal}${"course/"}${localStorage.getItem("course_id")}${"/leassons"}`, {
        method: 'GET',
        headers:{
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
    }).then((response) => response.json())
    .then((data) => {
      console.log(data)
      setLeassons(data.leassons)
      if (data.quiz){
        setQuiz(data.quiz)
      }
    })
    .catch((err) => {
      console.log(err);
      navigate("/login")
    });
  }, []);
  return (
    <div>
      <div className="description">
          <h2>{title}</h2>
      </div>
      <div className="container">
        <MainContent video={video} title={title}/>
        <Zidebar leassons={leassons} handleVideo={setVideo} handleTitle={setTitle} quiz={quiz}/>
      </div>
    </div>
    
  );
}
