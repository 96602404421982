import logopepe from '../static/logopepe.png'
import logopepe2 from '../static/logopepe2.png'

import { useState,useEffect,React } from "react";

const MyFunction = () => {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1400);
    
  
    const updateMedia = () => {
      setDesktop(window.innerWidth > 1400);
      
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  
    return (
      <div>
        {isDesktop ? (
          <div>
            <img className='logo-pepe' src={logopepe} />
          </div>
        ) : (
          <div>
            <img className='logo-pepe2' src={logopepe2} />
          </div>
        )}
      </div>
    );
  }

  export default MyFunction