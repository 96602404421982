import React from 'react'

function cursosadmin() {
  return (
    <div>
        <fieldset>
        <legend>
              <h1>Administrador de cursos</h1>
          </legend>
          <table>
              <thead>
                  <tr>
                      <th>
                          Cursos
                      </th>

                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          curso 1
                      </td>
                  </tr>
              </tbody>
              <tfoot>
                  <tr>
                      <td>
                          <button>agregar nuevo curso</button>
                      </td>
                  </tr>
              </tfoot>
          </table>
        </fieldset>
          

          <fieldset>
          <legend>
              <h1>Lecciones </h1>
          </legend>
          <table>
              <thead>
                  <tr>
                      <th>
                          Leccion
                      </th>

                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          lecciones
                      </td>
                  </tr>
              </tbody>
              <tfoot>
                  <tr>
                      <td>
                          <button>agregar nueva Leccion</button>
                      </td>
                  </tr>
              </tfoot>
          </table>
          </fieldset>

          <fieldset>
          <legend>
              <h1>Material</h1>
          </legend>
          <table>
              <thead>
                  <tr>
                      <th>
                          Materiales
                      </th>

                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          libros
                      </td>
                      <td>
                          audios
                      </td>
                  </tr>
              </tbody>
              <tfoot>
                  <tr>
                      <td>
                          <button>agregar nuevo material</button>
                      </td>
                  </tr>
              </tfoot>
          </table>
          </fieldset>
    
          <fieldset>
          <legend>
              <h1>Preguntas despues del curso</h1>
          </legend>
          <table>
              <thead>
                  <tr>
                      <th>
                          Preguntas
                      </th>

                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          preguntas
                      </td>
                      <td>
                          pregunta
                      </td>
                  </tr>
              </tbody>
              <tfoot>
                  <tr>
                      <td>
                          <button>agregar una nueva Pregunta</button>
                      </td>
                  </tr>
              </tfoot>
          </table>
          </fieldset>

     </div>
        
    
  )
}

export default cursosadmin