import React from 'react'


function lecciones(props) {
  console.log(props)
  return (
    <div>
      <grid>
          <div className='video'>
          <row>
          video
          </row>
          </div>
          <div className='descripcion'>
          <br/>
          <row>
          description
          
          </row>
          </div>
          <col/>
      </grid>
 
    </div>
  )
}

export default lecciones