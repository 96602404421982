import React, { useState } from 'react';

function Editor() {
  const [showForm, setShowForm] = useState(false);
  const [courses, setCourses] = useState([
    { id: 1, title: 'Curso 1', description: 'Descripción del curso 1' },
    { id: 2, title: 'Curso 2', description: 'Descripción del curso 2' },
    { id: 3, title: 'Curso 3', description: 'Descripción del curso 3' },
  ]);

  const handleAddCourse = () => {
    setShowForm(true);
  };

  const handleEditCourse = (id) => {
   
  };

  const handleDeleteCourse = (id) => {

  };

  const handleSubmit = (event) => {

    setShowForm(false);
    event.preventDefault();
  };

  return (
    <div>
      <h1>Mis cursos de e-learning</h1>

      <ul>
        {courses.map((course) => (
          <li key={course.id}>
            <h2>{course.title}</h2>
            <p>{course.description}</p>
            <button onClick={() => handleEditCourse(course.id)}>Editar</button>
            <button onClick={() => handleDeleteCourse(course.id)}>Eliminar</button>
          </li>
        ))}
      </ul>

      <button onClick={handleAddCourse}>Agregar curso</button>

      {showForm && (
        <form onSubmit={handleSubmit}>
          <label htmlFor="title">Título:</label>
          <input type="text" id="title" name="title" required />

          <label htmlFor="description">Descripción:</label>
          <textarea id="description" name="description" required></textarea>

          <button type="submit">Guardar</button>
          <button type="button" onClick={() => setShowForm(false)}>Cancelar</button>
        </form>
      )}
    </div>
  );
}

export default Editor;
