import React from 'react'

import audioimagen from '../recursos/imagenes/audio-imagen.jpeg'

import audio1 from '../recursos/audios/Habitos-Atomicos.mp3'
import audio2 from '../recursos/audios/Meditacion1-Calma.mp3'
import audio3 from '../recursos/audios/Meditacion2-Gratitud.mp3'
import audio4 from '../recursos/audios/Meditacion3-Sueño.mp3'
import audio5 from '../recursos/audios/Meditacion4-Respiración.mp3'
import audio6 from '../recursos/audios/Meditacion5-Dejalo ir.mp3'
import audio7 from '../recursos/audios/Caso-Johnny-Bravo.mp3'
import audio8 from '../recursos/audios/Ponte-Las-Pilas.mp3'

function meditaciones() {
  return (
    <div><div className="audios">
    <h2 className='h2-audio'>Meditaciones Y Audios</h2>
    <div className="row">
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
            <h1 className="card-title">Hábitos Atómicos</h1>
            <audio controls>
              <source src={audio1} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
            <h1 className="card-title">Ponte Las Pilas Pepe</h1>
            <audio controls>
              <source src={audio8} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
            <h1 className="card-title">Caso Johnny Bravo</h1>
            <audio controls>
              <source src={audio7} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>
      
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
          <h1 className="card-title">Meditación Calma</h1>
            <audio controls>
              <source src={audio2} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
          <h1 className="card-title">Meditación Gratitud</h1>
            <audio controls>
              <source src={audio3} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
          <h1 className="card-title">Meditación Déjalo Ir</h1>
            <audio controls>
              <source src={audio6} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
          <h1 className="card-title">Meditación Sueño</h1>
            <audio controls>
              <source src={audio4} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>  
      <div className="col-sm-6 col-md-4">
        <div className="card1">
          <img
            src={audioimagen}
            className="card-img-top"
            alt="Audio 1"
          />
          <div className="card-body">
          <h1 className="card-title">Meditación Respiración</h1>
            <audio controls>
              <source src={audio5} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        </div>
      </div>
            </div>
            </div>
            </div>
  )
}

export default meditaciones