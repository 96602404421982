import React, { useState } from 'react'
import Modalpremium from './modalpremium';

function Usuarios() {
  const [show, setShow] = useState(false)
  const [usuarios, setUsuarios] = useState ([
    {
       "phone":"5566774422",
       "id":1,
       "company":"Maycon Latam",
       "country":"México",
       "city":"CDMX",
       "created_at":"2023-03-14",
       "score":0,
       "position":"Team Lead",
       "email":"j.torres@mycom.net",
       "fullname":"Juan Arnulfo Torres Almeira",
       "birthdate":"1980-06-01",
       "state":"CDMX",
       "admin":true,
       "start_date":"None",
       "end_date":"None"
    }
 ]);

 

  function getUsuarios() {
    fetch("http://127.0.0.1:5000/api/v1/login")
    .then((Response) => {
      return Response.json()
    })
    .then((usuarios) => {
      setUsuarios(usuarios.users)})
  };

  function handleUpdate(data) {
    fetch("http://127.0.0.1:5000/api/v1/login", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      return Response.json()
    })
    .catch(error => {
      
    });
  }
  
  function doRow(usuario){
    
    return(
      <tbody>
        <tr> 
          <td>{usuario.id}</td>
          <td>{usuario.email}</td>
          <td>{usuario.fullname}</td>
          <td>{usuario.phone}</td>
          <td>{usuario.company}</td>
          <td>{usuario.position}</td>
          <td>{usuario.created_at}</td>
          <td>{usuario.start_date}</td>
          <td>{usuario.end_date}</td>
        </tr>
      </tbody>
    )
  }
  getUsuarios();
  handleUpdate ();

  return (

  <div style={{overflowX : 'auto'}}>
    <div className='header'> Usuarios Premium </div>
    <div className='btn-crear'>
      <button  className='btn'  type='button' onClick={() => setShow(true)}>agregar Premium</button>
      <Modalpremium onClose={() => setShow(false)} show={show}/>
    </div>
    <table className='users'>
      <thead>
        <tr>
          <th>Id</th>
          <th>Email</th>
          <th>Nombre</th> 
          <th>Telefono</th>
          <th>Empresa</th>
          <th>Puesto</th>
          <th>Fecha de Creacion</th>
          <th>Inicio Premium</th>
          <th>Fin Premium</th>
        </tr>
      </thead>
      {
        usuarios.map(doRow)
      }
    </table>
  </div>
  )
}

export default Usuarios

