import React, { useState } from "react";


function Formulario() {
  const [respuestas, setRespuestas] = useState({
    pregunta1: "",
    pregunta2: "",
    pregunta3: "",
    pregunta4: "",
    pregunta5: "",
    pregunta6: "",
    pregunta7: "",
    pregunta8: "",
    pregunta9: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRespuestas({ ...respuestas, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(respuestas);
  };

  return (
    <form onSubmit={handleSubmit} className="formulario">
        <h1>Reflexiones Sobre La Línea</h1>
      <div className="pregunta">
        <label htmlFor="pregunta1">Pregunta 1:</label>
        <input
          type="text"
          id="pregunta1"
          name="pregunta1"
          value={respuestas.pregunta1}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>

      <div className="pregunta">
        <label htmlFor="pregunta2">Pregunta 2:</label>
        <input
          type="text"
          id="pregunta2"
          name="pregunta2"
          value={respuestas.pregunta2}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>

      <div className="pregunta">
        <label htmlFor="pregunta3">Pregunta 3:</label>
        <input
          type="text"
          id="pregunta3"
          name="pregunta3"
          value={respuestas.pregunta3}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>

      <div className="pregunta">
        <label htmlFor="pregunta4">Pregunta 4:</label>
        <input
          type="text"
          id="pregunta4"
          name="pregunta4"
          value={respuestas.pregunta4}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>

      <div className="pregunta">
        <label htmlFor="pregunta5">Pregunta 5:</label>
        <input
          type="text"
          id="pregunta5"
          name="pregunta5"
          value={respuestas.pregunta5}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>

      <div className="pregunta">
        <label htmlFor="pregunta6">Pregunta 6:</label>
        <input
          type="text"
          id="pregunta6"
          name="pregunta6"
          value={respuestas.pregunta6}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>
      <div className="pregunta">
        <label htmlFor="pregunta7">Pregunta 7:</label>
        <input
          type="text"
          id="pregunta7"
          name="pregunta7"
          value={respuestas.pregunta7}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>
      <div className="pregunta">
        <label htmlFor="pregunta8">Pregunta 8:</label>
        <input
          type="text"
          id="pregunta8"
          name="pregunta8"
          value={respuestas.pregunta8}
          onChange={handleChange}
        />
      </div>
      <br/>
      <br/>
      <div className="pregunta">
        <label htmlFor="pregunta9">Pregunta 9:</label>
        <input
          type="text"
          id="pregunta9"
          name="pregunta9"
          value={respuestas.pregunta9}
          onChange={handleChange}
        />
      </div>

      <button type="submit">Enviar</button>
    </form>
  );
}

export default Formulario;
