import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ipGlobal from '../config/global'
import Square from './square'




export default function CoursesBasic(){
    const [courses, setCourses] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (!(localStorage.getItem('token')) || localStorage.getItem('token') == 'null'){ navigate("/login")}
        fetch(`${ipGlobal}${"courses_basics"}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((data) => {
          console.log(data.courses)
          setCourses(data.courses)
        })
        .catch((err) => {
          console.log(err);
          navigate("/login")
        });
    }, []);
    let body = null;
    if (courses){
        // courses.map((course) => Square(course))
        body = courses.map((course) => <Square course = {course}/>)
    }


    return(
        
        <div >

<               div className="note">
                    <h3>Pasos A Seguir</h3> 
                    <ul className="steps-list">
                        <li>Paso 1 : Curso Sobre La Línea,</li>
                        <li>Paso 2 : Curso Actitud y Mentalidad De Campeonato,</li>
                        <li>Paso 3 : Curso Liderazgo Servicial E Involucramiento,</li>
                        <li>Paso 4 : Audio Ponte Las Pilas Pepe (se encuentra en meditaciones),</li>
                        <li>Paso 5 : Curso Aprende A Escuchar, </li>
                        <li>Paso 6 : Curso Conflicto Constructivo,</li>
                        <li>Paso 7 : Curso Feedback Y Retroalimentación,</li>
                        <li>Paso 8 : Curso Administracion Del tiempo,</li>
                        <li>Paso 9 : Curso Planeación,</li>
                        <li>Paso 10 : Curso Hábitos Átomicos (se encuentra en meditaciones) </li>
                    </ul>
                </div>
            <br/>
            <div> 
            <div className="grid-courses"> {body} </div>
            <br/>
            <br/>
            </div>
             
        </div>
    )
}

