import React from 'react'

const modalCursos = props => {
    if (!props.show){
        return null
    }

    return (
        <div className='modal1'>
            <div className='modal1-content'>
                <div className='modal1-header'>
                    <h4 className='Mensaje-del-día'>{props.title}</h4>
                </div>
                <div className='modal1-body'>
                    "{props.message}"
                    <br/>
                    <br/>
                    {props.author}
                </div>
                <div className='modal1-footer'>
                    <button onClick={props.onClose} className='button-modal1'>Cerrar</button>
                </div>
            </div>

        </div>
    )

}

export default modalCursos