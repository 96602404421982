import React from 'react';
import { Container, Typography, Button, Grid, Card, CardMedia, CardContent, AppBar, Toolbar } from '@mui/material';

const LandingPage = () => {
  return (
    <div>
     
      <AppBar position="static">
        <Toolbar>
          
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Mi Landing Page
          </Typography>
          <Button color="inherit">Iniciar sesión</Button>
        </Toolbar>
      </AppBar>

      
      <Container sx={{ my: 4 }}>
        <Typography variant="h2" sx={{ textAlign: 'center', mt: 4 }}>
          Sobre La Linea 
        </Typography>
        <Typography variant="h5" sx={{ textAlign: 'center', my: 2 }}>
          Coaching Empresarial
        </Typography>
      </Container>

      
      <Container sx={{ my: 4 }}>
        <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
          Características principales
        </Typography>
        <Grid container spacing={4}>
          <div item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia component="img" height="140" image="/path-to-image" alt="Feature 1" />
              <CardContent>
                <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                  Característica 1
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Descripción de la característica 1.
                </Typography>
              </CardContent>
            </Card>
            <br/>
            <Card>
              <CardMedia component="img" height="140" image="/path-to-image" alt="Feature 1" />
              <CardContent>
                <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                  Característica 1
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Descripción de la característica 1.
                </Typography>
              </CardContent>
            </Card>
          </div>
          
        </Grid>
        
      </Container>

      

      
      <footer>
        <Typography variant="h6" sx={{ textAlign: 'center', my: 4 }}>
          Contacto
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
          Información de contacto y enlaces a redes sociales.
        </Typography>
        
      </footer>
    </div>
  );
};

export default LandingPage;
